<template>
  <div>
    <Modal
      modalTitle="Dodawanie produktu"
      modalSubtitle="Za chwilę zostaniesz przekierowany do koszyka"
      modalWaitOption
    />
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import Modal from "../components/basket/Modal";

export default {
  name: "AddProduct",
  async mounted() {
    this.setBackToShoppingUrl(this.getReferrerOfDocument());
    await this.addProductToBasketUsingUrlParameters();
    this.redirectToBasket();
  },
  components: { Modal },
  methods: {
    ...mapActions([
      "addProductToBasketWithProductCode",
      "setBackToShoppingUrl",
      "showAlert"
    ]),
    ...mapMutations(["SET_AS_GIFT"]),
    redirectToBasket() {
      this.$router.push({ name: "Basket" });
    },
    getReferrerOfDocument() {
      return document.referrer;
    },

    addProductToBasketUsingUrlParameters() {
      this.setBasketAsGift();
      let productData = {
        offerCode: getParameterFromUrl(this.$route, "offerCode"),
        startingDate: getParameterFromUrl(
          this.$route,
          "packetFirstPhysicalEmissionDate"
        ),
        gross: getParameterFromUrl(this.$route, "price")
      };
      return this.addProductToBasketWithProductCode(productData);
    },
    setBasketAsGift() {
      if (getParameterFromUrl(this.$route, "isGift")) {
        this.SET_AS_GIFT();
      }
    }
  }
};

function getParameterFromUrl(route, parameterName) {
  return route.query[parameterName] ? route.query[parameterName] : null;
}
</script>
