<template>
  <div v-if="isPhysicalDeliverySelected" class="container__order">
    <p class="form__box__title">Adres dostawy</p>
    <div class="form__box">
      <div class="form__box">
        <p class="input__title">Kraj</p>
        <div class="form__inputBox">
          <select
            v-model="country"
            @change="changeCountry($event)"
            class="form__input"
            id="delivery_address__country"
            v-on:change="
              $emit('show-company-order-info', showCompanyOrderInfo())
            "
          >
            <option
              v-for="(country, index) in countries"
              :value="country"
              :key="index"
            >
              {{ country }}
            </option>
          </select>
        </div>
      </div>

      <div
        :class="
          $v.firstName.$dirty && $v.firstName.$invalid
            ? 'form__box form__box--error'
            : 'form__box'
        "
      >
        <p class="input__title">Imię</p>
        <div
          :class="
            $v.firstName.$invalid
              ? 'form__inputBox'
              : 'form__inputBox form__inputBox--valid'
          "
        >
          <input
            v-model="firstName"
            @blur="$v.firstName.$touch"
            type="text"
            class="form__input"
            placeholder="podaj imię"
          />
        </div>
        <p v-if="!$v.firstName.required" class="input__error">
          Podaj imię.
        </p>
      </div>

      <div
        :class="
          $v.lastName.$dirty && $v.lastName.$invalid
            ? 'form__box form__box--error'
            : 'form__box'
        "
      >
        <p class="input__title">Nazwisko</p>
        <div
          :class="
            $v.lastName.$invalid
              ? 'form__inputBox'
              : 'form__inputBox form__inputBox--valid'
          "
        >
          <input
            v-model="lastName"
            @blur="$v.lastName.$touch"
            type="text"
            class="form__input"
            placeholder="podaj nazwisko"
          />
        </div>
        <p v-if="!$v.lastName.required" class="input__error">
          Podaj nazwisko.
        </p>
      </div>

      <div
        :class="
          $v.street.$dirty && $v.street.$invalid
            ? 'form__box form__box--error'
            : 'form__box'
        "
      >
        <p class="input__title">Ulica i numer domu/mieszkania</p>
        <div
          :class="
            $v.street.$invalid
              ? 'form__inputBox'
              : 'form__inputBox form__inputBox--valid'
          "
        >
          <input
            v-model="street"
            @blur="$v.street.$touch"
            type="text"
            class="form__input"
            placeholder="podaj nazwę ulicy i numer domu/mieszkania"
          />
        </div>
        <p v-if="!$v.street.required" class="input__error">
          Podaj nazwę ulicy i numer domu/mieszkania.
        </p>
      </div>

      <div
        :class="
          $v.postalCode.$dirty && $v.postalCode.$invalid
            ? 'form__box form__box--error'
            : 'form__box'
        "
      >
        <p class="input__title">Kod pocztowy</p>
        <div
          :class="
            $v.postalCode.$invalid
              ? 'form__inputBox'
              : 'form__inputBox form__inputBox--valid'
          "
        >
          <the-mask
            v-model="postalCode"
            v-if="isPolandChosen"
            @blur.native="$v.postalCode.$touch"
            type="tel"
            class="form__input"
            placeholder="podaj kod pocztowy"
            id="postalCode"
            mask="##-###"
            :maxlength="6"
          />
          <input
            v-else
            v-model="postalCode"
            @blur="$v.postalCode.$touch"
            class="form__input"
            placeholder="podaj kod pocztowy"
            id="postalCode"
          />
        </div>

        <p
          v-if="!$v.postalCode.length || !$v.postalCode.numeric"
          class="input__error"
        >
          Podaj kod pocztowy w formacie 00-000.
        </p>
      </div>

      <div
        :class="
          $v.city.$dirty && $v.city.$invalid
            ? 'form__box form__box--error'
            : 'form__box'
        "
      >
        <p class="input__title">Miasto</p>
        <div
          :class="
            $v.city.$invalid
              ? 'form__inputBox'
              : 'form__inputBox form__inputBox--valid'
          "
        >
          <input
            v-model="city"
            @blur="$v.city.$touch"
            type="text"
            class="form__input"
            placeholder="podaj nazwę miasta"
          />
        </div>
        <p v-if="!$v.city.required" class="input__error">
          Podaj nazwę miasta.
        </p>
      </div>

      <div
        class="form__box"
        :class="{ 'form__box--error': $v.phone.$dirty && $v.phone.$invalid }"
      >
        <p class="input__title">Telefon dla kuriera/listonosza</p>
        <div
          class="form__inputBox"
          :class="{
            'form__inputBox--valid': !!$v.phone.$model && !$v.phone.$invalid
          }"
        >
          <input
            v-model="phone"
            @blur="$v.phone.$touch"
            type="tel"
            class="form__input"
            placeholder="telefon dla kuriera/listonosza"
            :maxlength="9"
          />
        </div>
        <p v-if="!$v.phone.numeric || !$v.phone.minLength" class="input__error">
          Podaj poprawny telefon dla kuriera/listonosza.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { TheMask } from "vue-the-mask";
import {
  minLength,
  maxLength,
  numeric,
  required
} from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import { COUNTRIES } from "../../mixins/countries";

export default {
  name: "DeliveryAddressForm",
  mixins: [validationMixin],
  components: { "the-mask": TheMask },
  props: {
    isPhysicalDeliverySelected: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(["getUserType"]),
    showCompanyInvoice: function() {
      return this.getUserType !== "Private";
    },
    isPolandChosen() {
      return this.country === "Polska";
    }
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      street: "",
      postalCode: "",
      city: "",
      phone: "",
      country: "Polska",
      countries: COUNTRIES
    };
  },
  validations() {
    const form_validation = {
      firstName: {
        required
      },
      lastName: {
        required
      },
      street: {
        required
      },
      postalCode: {
        minLength: minLength(5),
        maxLength: maxLength(15),
        required
      },
      city: {
        required
      },
      phone: {
        length: minLength(9),
        numeric
      }
    };
    return this.changePostalCodeMinLength(form_validation);
  },
  methods: {
    changeCountry(event) {
      this.country = event.target.value;
    },
    showCompanyOrderInfo: function() {
      return this.getUserType == null && this.country !== "Polska";
    },
    changePostalCodeMinLength(form_validations) {
      if (this.isPolandChosen) {
        form_validations.postalCode.minLength = minLength(5);
      } else {
        form_validations.postalCode.minLength = minLength(2);
      }
      return form_validations;
    }
  },
  mounted() {
    this.isPolandChosen;
  }
};
</script>

<style lang="scss" scoped>
.form__box {
  &__title {
    @include font-xl-n;
    color: #181818;
    margin-bottom: 16px;
  }
}
select.form__input {
  background: white;
  appearance: none;
  position: relative;
  background: url(https://cdn.files.smcloud.net/t/chevron-down.svg);
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 50%;
}
label.invoice__check__label input {
  position: absolute;
  left: -100vw;
}
.invoice__check__label input:checked ~ .checkmark:after {
  display: block;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 7px;
  top: 2px;
  width: 9px;
  height: 16px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.invoice__check__label input:checked ~ .checkmark {
  background-color: #3cabda;
  border: 1px solid transparent;
}
.invoice__check__label {
  position: relative;
  padding-left: 37px;
  color: #505050;
  font-size: 14px;
}
.company__order_info___container {
  border: 1px solid #fbb040;
  padding: 20px 20px 20px 60px;
  border-radius: 8px;
  margin-top: 24px;
  position: relative;
}
.company__order_info__text,
.company__order_info__phone,
.company__order_info__mail {
  color: #4f4f4f;
  font-size: 14px;
  margin-bottom: 16px;
  line-height: 18px;
}
.company__order_info__phone {
  margin-bottom: 16px;
}
.company__order_info__mail a {
  color: #3ec6ff;
}
p.invoice__check {
  margin-top: 16px;
}
</style>
