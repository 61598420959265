<template>
  <div id="register-page">
    <Header />
    <Steps />
    <ErrorBox :errors="errors" ref="errorBox" />

    <div class="registerBox">
      <div class="container container--registerBox">
        <div class="register">
          <p class="register__title">Nie mam konta do zakupów</p>

          <p class="register__txt">
            Rejestracja ułatwi Ci wygodne korzystanie z zakupionych produktów.
          </p>

          <p class="register__txt">
            Do założenia konta wystarczy twój e-mail i hasło.
          </p>

          <div
            :class="
              $v.email.$dirty && $v.email.$invalid
                ? 'form__box form__box--error'
                : 'form__box'
            "
          >
            <p class="input__title">Adres e-mail</p>
            <div
              :class="
                $v.email.$invalid
                  ? 'form__inputBox'
                  : 'form__inputBox form__inputBox--valid'
              "
            >
              <input
                v-model="email"
                @blur="$v.email.$touch"
                type="email"
                class="form__input"
                placeholder="podaj adres e-mail"
              />
            </div>
            <p v-if="!$v.email.required" class="input__error">
              Podaj adres e-mail
            </p>
            <p v-if="!$v.email.email" class="input__error">
              Podaj poprawny adres e-mail
            </p>
            <p v-if="accountExist" class="input__warning">
              Na ten adres e-mail jest założone konto do zakupów w grupie ZPR
              Media. Zaloguj się do konta lub użyj innego adresu e-mail.
            </p>
          </div>

          <div
            :class="
              $v.password.$dirty && $v.password.$invalid
                ? 'form__box form__box--error'
                : 'form__box'
            "
          >
            <p class="input__title">Hasło</p>
            <div
              :class="
                $v.password.$invalid
                  ? 'form__inputBox'
                  : 'form__inputBox form__inputBox--valid'
              "
            >
              <input
                v-model="password"
                @blur="$v.password.$touch"
                type="password"
                class="form__input"
                placeholder="hasło powinno zawierać 6 znaków"
              />
            </div>
            <p v-if="!$v.password.required" class="input__error">Podaj hasło</p>
            <p v-if="!$v.password.minLength" class="input__error">
              Hasło powinno zawierać min. 6 znaków
            </p>
            <p v-if="!$v.password.maxLength" class="input__error">
              Hasło może liczyć maksymalnie 12 znaków
            </p>
          </div>

          <div
            :class="
              $v.passwordRepeat.$dirty && $v.passwordRepeat.$invalid
                ? 'form__box form__box--error'
                : 'form__box'
            "
          >
            <p class="input__title">Powtórz hasło</p>
            <div
              :class="
                $v.passwordRepeat.$invalid
                  ? 'form__inputBox'
                  : 'form__inputBox form__inputBox--valid'
              "
            >
              <input
                v-model="passwordRepeat"
                @blur="$v.passwordRepeat.$touch"
                type="password"
                class="form__input"
                placeholder="powtórz hasło"
              />
            </div>
            <p v-if="!$v.passwordRepeat.required" class="input__error">
              Powtórz hasło
            </p>
            <p v-if="!$v.passwordRepeat.sameAsPassword" class="input__error">
              Hasła powinny być takie same
            </p>
          </div>
        </div>
      </div>

      <div class="container container--rodo">
        <div class="rodo">
          <p class="register__title">Zgody prawne i marketingowe</p>

          <p class="rodo__check rodo__check--first">
            <label @click="$v.salesReg.$touch()" class="rodo__check__label">
              <input
                v-model="markAll"
                @click="checkAll()"
                type="checkbox"
                id="checkall-checkbox"
              />
              Zaznacz wszystkie zgody
              <span class="checkmark"></span>
            </label>
          </p>
          <p class="rodo__check">
            <label
              @click="$v.salesReg.$touch()"
              :class="
                $v.salesReg.$dirty && $v.salesReg.$invalid
                  ? 'rodo__check__label coloredRed'
                  : 'rodo__check__label'
              "
            >
              <input v-model="salesReg" type="checkbox" />
              Akceptuję
              <a
                href="https://prenumerata.murator.pl/warunki-sprzedazy/"
                class="text-underline"
                target="_blank"
                rel="noreferrer noopener"
              >
                regulamin sprzedaży
              </a>
              <span class="coloredRed">*</span>
              <span class="checkmark"></span>
            </label>
          </p>
          <p class="rodo__check rodo__txt">
            Zgadzam się na otrzymywanie od TIME S.A. informacji marketingowych,
            m.in. o nowościach, promocjach, rabatach, akcjach specjalnych:
          </p>
          <p class="rodo__check">
            <label class="rodo__check__label">
              <input v-model="newsletter" type="checkbox" />
              drogą elektorniczną (np. e-mail)
              <span class="checkmark"></span>
            </label>
          </p>
          <p class="rodo__check rodo__txt">
            Mam świadomość swojego prawa do odwołania zgody w każdym czasie,
            wysyłając e-mail na adres
            <a class="rodo__link" href="mailto:iod@grupazpr.pl"
              >iod@grupazpr.pl</a
            >
            .
          </p>
          <p class="rodo__check rodo__informTitle">
            INFORMACJE PODSTAWOWE DOTYCZĄCE PRZETWARZANIA DANYCH OSOBOWYCH
          </p>
          <p class="rodo__check rodo__informTxt">
            <span class="rodo__underline">Administratorem</span> Twoich danych
            jest TIME S.A. z siedzibą w Warszawie przy ul. Jubilerskiej 10,
            04-190 Warszawa.
            <span class="rodo__underline">Cele przetwarzania</span> to
            realizacja usługi, podpisanie umowy oraz marketing własny TIME S.A.,
            jeżeli wyraziłeś na to zgodę.
            <span class="rodo__underline">Podstawami prawnymi</span>
            przetwarzania są umowa sprzedaży/świadczenia usług oraz nasz prawnie
            uzasadniony interes. Odbiorcami danych są podmioty przetwarzające
            dane osobowe w naszym imieniu.
            <span class="rodo__underline">Twoje prawa</span> związane z
            przetwarzaniem danych: prawo sprzeciwu wobec przetwarzania danych w
            celach marketingowych, prawo dostępu do danych i inne prawa, o
            których mowa w szczegółowych informacjach o przetwarzaniu danych
            osobowych dostępnych
            <a
              class="rodo__link rodo__link--nu"
              href="https://rodo.grupazpr.pl/#time-klauzula-kliencka"
              target="_blank"
              >TUTAJ</a
            >.
          </p>

          <button
            :class="$v.$invalid ? 'btn btn--inactive' : 'btn btn--primary'"
            @click="
              $v.$touch();
              !$v.$invalid ? registerUser() : '';
            "
          >
            Zarejestruj się
          </button>

          <router-link
            class="btn btn--secondaryLight btn--return"
            to="/"
            tag="button"
          >
            Wróć do zakupów
          </router-link>
        </div>
      </div>

      <div class="container container--buttons">
        <div
          id="toggle-login-register"
          class="container container--register col1-2"
        >
          <div class="register-option">
            <div class="register-option__btnBox">
              <ToggleLoginRegister />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  required,
  email,
  minLength,
  maxLength,
  sameAs
} from "vuelidate/lib/validators";
import redirectMixin from "../mixins/redirectMixin";
import eloquaMixin from "../mixins/eloquaMixin";
import Steps from "../components/checkout/Steps";
import Header from "../components/basket/Header";
import ToggleLoginRegister from "../components/checkout/ToggleLoginRegister";
import { mapActions } from "vuex";
import ErrorBox from "../components/basket/ErrorBox";

export default {
  name: "Register",
  mixins: [validationMixin, redirectMixin, eloquaMixin],
  components: { ErrorBox, Header, Steps, ToggleLoginRegister },
  data() {
    return {
      email: "",
      password: "",
      passwordRepeat: "",
      markAll: false,
      salesReg: false,
      newsletter: false,
      errors: [],
      accountExist: false,
      elqCustomerGUID: undefined
    };
  },
  validations: {
    email: { required, email },
    password: { required, minLength: minLength(6), maxLength: maxLength(12) },
    passwordRepeat: {
      required,
      sameAsPassword: sameAs("password")
    },
    salesReg: { sameAs: val => val === true }
  },
  methods: {
    ...mapActions(["registerCustomer"]),
    checkAll() {
      if (!this.markAll) {
        this.salesReg = true;
        this.newsletter = true;
      } else {
        this.salesReg = false;
        this.newsletter = false;
      }
    },
    async registerUser() {
      this.errors = [];
      this.accountExist = false;

      try {
        await this.registerCustomer({
          login: this.email,
          password: this.password,
          regulationConsent: this.salesReg,
          emailMarketingConsent: this.newsletter,
          elqCustomerGUID: this.getElqCustomerGUID()
        });
      } catch (error) {
        if (error.code === 1) {
          this.accountExist = true;
          return;
        } else {
          this.errors.push(error.message);
          this.$refs.errorBox.$el.scrollIntoView({ behavior: "smooth" });
          return;
        }
      }

      this.redirectToOrder();
    }
  }
};
</script>

<style lang="scss" scoped>
.rodo {
  .register__title {
    margin-bottom: 16px;

    @include lg {
      font-size: 18px;
      line-height: 21px;
    }
  }

  @include lg {
    padding: 20px 24px 24px 24px;
  }

  .btn--return {
    margin-top: 16px;

    @include lg {
      margin-top: 24px;
    }
  }
}

.register {
  background-color: $lightBgColor;
  padding: 14px 8px;

  @include lg {
    max-width: 100%;
    width: 100%;
    padding: 40px 24px;
  }

  &__title {
    font-size: 18px;
    line-height: 21px;

    @include lg {
      font-size: 22px;
      line-height: 24px;
      margin-bottom: 32px;
    }
  }

  &__txt {
    color: #505050;
    font-size: 14px;
    line-height: 16px;
    margin-top: 10px;

    @include lg {
      font-size: 16px;
      line-height: 146%;
    }

    &:last-of-type {
      margin-bottom: 32px;
    }
  }

  &Box {
    @include lg {
      display: grid;
      max-width: 1024px;
      margin: 0 auto;
      grid-template-columns: 1fr 1fr;

      .container {
        width: 406px;
      }

      .container--rodo {
        order: 3;
      }

      .container--buttons {
        order: 2;
      }

      .container--registerBox,
      .container--rodo {
        margin-right: 4px;
      }

      .container--register {
        padding-left: 0;
        padding-right: 0;
      }

      .container--buttons {
        margin-left: 4px;
        display: flex;
        flex-direction: column;
        padding: 40px 24px 24px 24px;
        background: #fff;

        .register-option {
          padding: 0;
        }
      }

      .register-option__btnBox {
        flex-direction: column;

        .btn:first-of-type {
          margin-right: 0;
          margin-bottom: 8px;
        }

        .btn:last-of-type {
          margin-left: 0;
        }
      }
    }
  }
}
</style>
