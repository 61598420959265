import store from "@/store";
import { gtmManager } from "../gtm";

export default {
  checkUserIsAuthenticatedAndRedirectToLogin: (
    to,
    from,
    next,
    localStore = store
  ) => {
    if (!localStore.getters.isUserAuthenticated) next({ name: "Login" });
    else next();
  },
  checkUserIsAuthenticatedAndRedirectToOrder: (
    to,
    from,
    next,
    localStore = store
  ) => {
    if (localStore.getters.isUserAuthenticated) next({ name: "Order" });
    else next();
  },
  gtmChangePage: (to, from, next, localStore = store) => {
    if (to.name != "OrderConfirm") {
      localStore.commit("SET_DATA_LAYER");
      gtmManager.viewChange(localStore, to.path, to.name);
    }
    next();
  },
  checkUserIsAuthenticated: (to, from, next, localStore = store) => {
    if (!localStore.getters.isUserAuthenticated) {
      if (from.name != "Order") {
        localStore.dispatch("getOrCreateSessionKey");
      }
      localStore.dispatch("checkUserIsAuthenticated");
    }
    next();
  }
};
