<template>
  <li class="summary__item">
    <div class="summary__details">
      <p class="summary__product">Kod Rabatowy</p>
      <div class="summary__quantity">
        <span>{{ rabateCode.message }}</span>
      </div>
    </div>
    <div class="summary__value">
      <FormattedPrice
        :price="-this.rabateCode.rabateTotalValue"
        :currency="this.currency"
      />
      <span v-if="isPln(this.currency)" class="summary__tax rabateCode"
        >(z VAT)</span
      >
    </div>
  </li>
</template>

<script>
import FormattedPrice from "../utils/FormattedPrice";

export default {
  name: "RabateCodeSummary",
  components: { FormattedPrice },
  props: {
    rabateCode: Object,
    currency: String
  },
  methods: {
    isPln(currency) {
      return currency === "PLN";
    }
  }
};
</script>

<style lang="scss" scoped>
.summary {
  &__item {
    display: flex;
    margin-bottom: 8px;

    @include lg {
      margin-bottom: 16px;
    }
  }

  &__details {
    flex: 1;
  }

  &__product {
    font-weight: 400;
    margin-bottom: 6px;
    @include font-lg;
  }

  &__quantity {
    font-weight: 300;
    @include font-md;
  }

  &__value {
    display: flex;
    color: #eb5757;
    flex-direction: column;
    align-items: flex-end;
    font-weight: 500;
    @include font-lg;
  }

  &__tax {
    font-size: 12px;
    line-height: 14px;
    font-weight: 300;
    color: #000;
  }
}
</style>
