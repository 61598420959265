export default {
  BASKET_PAGE_ADD_ITEM: (state, item) => {
    if (!state.basketPage.items.includes(item))
      state.basketPage.items.push(item);
  },
  BASKET_PAGE_REPLACE_ITEMS: (state, items) => {
    state.basketPage.items = items;
  },
  BASKET_PAGE_SET_AMOUNT: (state, amount) => {
    state.basketPage.amount = amount;
  },
  BASKET_PAGE_SET_RABATE: (state, rabateCode) => {
    state.basketPage.rabateCode = rabateCode;
    state.basketPage.rabateCodes = [];
  },
  BASKET_PAGE_CLEAR_ITEMS: state => {
    state.basketPage.items = [];
  },
  BASKET_PAGE_ADD_DELIVERIES: (state, deliveries) => {
    state.basketPage.deliveries = deliveries;
  },
  BASKET_PAGE_ADD_PAYMENTS: (state, payments) => {
    state.basketPage.payments = payments;
  },
  BASKET_PAGE_SET_LOGGED_CUSTOMER: (state, loggedCustomer) => {
    state.basketPage.loggedCustomer = loggedCustomer;
  },
  BASKET_PAGE_DELETE_ITEM: (state, itemId) => {
    const itemIndex = state.basketPage.items.findIndex(
      ({ cartOfferId }) => cartOfferId === itemId
    );
    state.basketPage.items.splice(itemIndex, 1);
  },
  BASE_PAGE_UPDATE_ITEM_QUANTITY: (state, { itemId, quantity, price }) => {
    const item = state.basketPage.items.find(i => i.id === itemId);
    item.quantity = quantity;
    item.price = price;
  },
  BASE_PAGE_UPDATE_ITEM_QUANTITY_WEAVER: (
    state,
    { itemId, quantity, price }
  ) => {
    const item = state.basketPage.items.find(i => i.cartOfferId === itemId);
    item.quantity = quantity;
    item.price = price;
  },
  SET_SESSION_ID: (state, sessionId) => {
    state.sessionId = sessionId;
  },
  SET_CART_TYPE: (state, cartType) => {
    state.cartType = cartType;
  },
  SHOW_ALERT: (state, message) => {
    console.error(message);
  },
  SET_BACK_TO_SHOPPING_URL: (state, url) => {
    state.backToShoppingUrl = url;
  },
  SET_CUSTOMER_DATA: (state, data) => {
    state.customerData = data;
  },
  SET_CUSTOMER_HASH: (state, hash) => {
    state.customerHash = hash;
  },
  SET_CUSTOMER_EMAIL: (state, data) => {
    if (state.customerData === undefined) {
      state.customerData = { email: data };
    } else {
      state.customerData.email = data;
    }
  },
  SET_USER_IS_AUTHENTICATED: (state, isUserAuthenticated) => {
    state.isUserAuthenticated = isUserAuthenticated;
  },
  SET_USER_NO_REGISTER: (state, isUserNoRegister) => {
    state.isUserNoRegister = isUserNoRegister;
  },
  SET_ORDER_DATA: (state, data) => {
    if (data.orders != null) {
      state.orderData = data;

      const order = data.orders.pop();
      if (state.basketPage.deliveries.length === 0 && order !== undefined)
        state.basketPage.deliveries = [order.delivery];

      if (state.basketPage.payments.length === 0 && order !== undefined)
        state.basketPage.payments = [order.payment];

      if (state.basketPage.items.length === 0 && order !== undefined)
        state.basketPage.items = order.items;
    }
  },
  ADD_ERROR: (state, error) => {
    state.errors[error.name] = error.message;
  },
  ADD_INVOICE_DATA: (state, invoiceData) => {
    state.customerData.invoiceAdresses.unshift(invoiceData);
  },
  SET_DATA_LAYER: state => {
    if (state.dataLayer === undefined) {
      state.dataLayer = window.dataLayer || [];
    }
  },
  SET_ORDERS_FROM_TRANSACTION_HISTORY: (state, orders) => {
    state.orderData.orders = orders;
  },
  SET_TRANSACTION_ID: (state, transactionId) => {
    state.orderData.transactionId = transactionId;
  },
  SET_SERVICE: (state, service) => {
    state.service = service;
  },
  REMOVE_ITEMS_DUPLICATES: state => {
    const cleanedItems = Array.from(
      new Set(state.basketPage.items.map(item => item.id))
    ).map(id => state.basketPage.items.find(item => item.id === id));
    state.basketPage.items = cleanedItems;
  },
  SET_REFERER_LABEL: (state, refererLabel) => {
    state.refererLabel = refererLabel;
  },
  SET_AUTHENTICATION_REFERER: (state, referer) => {
    state.authenticationReferer = referer;
  },
  SET_AS_GIFT: state => {
    state.isGift = true;
  },
  SET_CHOSEN_PAYMENT_CODE: (state, paymentCode) => {
    state.chosenPaymentCode = paymentCode;
  },
  SET_CHOSEN_DELIVERY_ID: (state, deliveryId) => {
    state.chosenDeliveryId = deliveryId;
  },
  SET_DELIVERY_ADDRESS_DATA: (state, deliveryAddressData) => {
    let deliveryAddress = deliveryAddressData.deliveryAddresses[0];
    // let phone_number = deliveryAddressData.invoiceAdresses[0].phone;
    if (deliveryAddress != undefined && deliveryAddress != null) {
      state.deliveryAddressData = {
        firstName: deliveryAddress.firstName,
        lastName: deliveryAddress.lastName,
        street: deliveryAddress.street,
        postalCode: deliveryAddress.postalCode,
        city: deliveryAddress.city
        // phone: phone_number
      };
    }
  },
  SET_USER_IP_ADDRESS: (state, userIpAddress) => {
    state.userIpAddress = userIpAddress;
  },
  SET_DEBUG: (state, message) => {
    state.debug = message;
  }
};
