const orderDataSourceIDs = {
  "koszyk.murator.pl": "CB440FFF-6F9E-4369-84B7-C348DC1B4ED8",
  "koszyk.muratordom.pl": "CB440FFF-6F9E-4369-84B7-C348DC1B4ED8",
  "koszyk.wiemiwybieram.pl": "CB440FFF-6F9E-4369-84B7-C348DC1B4ED8",
  "vivelo.pl": "1b0db2f0-ec51-ed11-b89f-005056a49b94"
};

export const getOrderDataSourceId = url => {
  const hostname = new URL(url).hostname;

  for (const key of Object.keys(orderDataSourceIDs)) {
    if (hostname.includes(key)) {
      return orderDataSourceIDs[key];
    }
  }

  return null;
};
