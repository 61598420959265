var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"steps container"},[_c('div',{class:_vm.renderClasses(
        ['Login', 'Register', 'Order', 'OrderConfirm'],
        'steps__step'
      ),attrs:{"id":"first-step"}},[_c('div',{staticClass:"steps__content"},[_c('p',{class:_vm.renderClasses(
            ['Login', 'Register', 'Order', 'OrderConfirm'],
            'steps__number'
          )},[_c('span',{staticClass:"steps__number__value"},[_vm._v("1")])]),_c('p',{class:_vm.renderClasses(
            ['Login', 'Register', 'Order', 'OrderConfirm'],
            'steps__name'
          )},[_vm._v(" Twoje dane ")])])]),_c('div',{class:_vm.renderClasses(['Order', 'OrderConfirm'], 'steps__step'),attrs:{"id":"second-step"}},[_c('div',{staticClass:"steps__content"},[_c('p',{class:_vm.renderClasses(['Order', 'OrderConfirm'], 'steps__number')},[_c('span',{staticClass:"steps__number__value"},[_vm._v("2")])]),_c('p',{class:_vm.renderClasses(['Order', 'OrderConfirm'], 'steps__name')},[_vm._v(" Zamówienie ")])]),_c('div',{staticClass:"step__line"})]),_c('div',{class:_vm.renderClasses(['OrderConfirm'], 'steps__step'),attrs:{"id":"third-step"}},[_c('div',{staticClass:"steps__content"},[_c('p',{class:_vm.renderClasses(['OrderConfirm'], 'steps__number')},[_c('span',{staticClass:"steps__number__value"},[_vm._v("3")])]),_c('p',{class:_vm.renderClasses(['OrderConfirm'], 'steps__name')},[_vm._v(" Potwierdzenie ")])]),_c('div',{staticClass:"step__line"})])])}
var staticRenderFns = []

export { render, staticRenderFns }