<template>
  <div class="steps container">
    <div
      id="first-step"
      :class="
        renderClasses(
          ['Login', 'Register', 'Order', 'OrderConfirm'],
          'steps__step'
        )
      "
    >
      <div class="steps__content">
        <p
          :class="
            renderClasses(
              ['Login', 'Register', 'Order', 'OrderConfirm'],
              'steps__number'
            )
          "
        >
          <span class="steps__number__value">1</span>
        </p>
        <p
          :class="
            renderClasses(
              ['Login', 'Register', 'Order', 'OrderConfirm'],
              'steps__name'
            )
          "
        >
          Twoje dane
        </p>
      </div>
    </div>

    <div
      id="second-step"
      :class="renderClasses(['Order', 'OrderConfirm'], 'steps__step')"
    >
      <div class="steps__content">
        <p :class="renderClasses(['Order', 'OrderConfirm'], 'steps__number')">
          <span class="steps__number__value">2</span>
        </p>
        <p :class="renderClasses(['Order', 'OrderConfirm'], 'steps__name')">
          Zamówienie
        </p>
      </div>
      <div class="step__line"></div>
    </div>

    <div
      id="third-step"
      :class="renderClasses(['OrderConfirm'], 'steps__step')"
    >
      <div class="steps__content">
        <p :class="renderClasses(['OrderConfirm'], 'steps__number')">
          <span class="steps__number__value">3</span>
        </p>
        <p :class="renderClasses(['OrderConfirm'], 'steps__name')">
          Potwierdzenie
        </p>
      </div>
      <div class="step__line"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Steps",
  methods: {
    renderClasses(routes, className) {
      let classes;
      for (const route of routes) {
        if (this.$route.name === route) {
          classes = `${className} ${className}--active`;
          break;
        } else {
          classes = `${className}`;
        }
      }
      return classes;
    }
  }
};
</script>

<style lang="scss" scoped>
.steps {
  margin: 16px auto;
  display: flex;
  justify-content: space-around;
  background-color: $lightBgColor;
  padding: 0 10px;

  @include lg {
    background-color: transparent;
    max-width: 552px;
    padding: 0;
  }

  &__step {
    padding: 5px 0;
    display: flex;
    flex: 1;
    flex-direction: row-reverse;

    &--active {
      .step__line {
        background-color: var(--mainColor);
      }
    }

    &:first-of-type {
      flex-grow: 0;

      .steps__name {
        white-space: nowrap;
      }
    }
  }
  &__number {
    text-align: center;
    color: $lightBgColor;
    padding: 10px;
    margin: 0 auto 5px auto;
    background-color: $btnSecondaryColor;
    width: 25px;
    height: 25px;
    border-radius: 100px;
    padding: 6px 9px;
    font-size: 12px;
    line-height: 14px;
    position: relative;

    @include lg {
      margin-bottom: 8px;
    }

    &__value {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    @include lg {
      height: 40px;
      width: 40px;
      font-size: 14px;
      line-height: 16px;
      font-weight: 500;
    }

    &--active {
      background-color: var(--mainColor);
    }
  }
  &__name {
    text-align: center;
    font-size: 12px;
    line-height: 18px;
    color: $btnSecondaryColor;

    &--active {
      color: var(--mainColor);
    }

    @include lg {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.step {
  &__line {
    flex: 1;
    background-color: $btnSecondaryColor;
    height: 1px;
    margin: auto auto;
  }
}
</style>
