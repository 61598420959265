<template>
  <span>{{ number }} {{ description }}</span>
</template>

<script>
import { polishPlurals } from "polish-plurals";

export default {
  name: "Quantity",
  props: {
    number: {
      type: Number,
      required: true
    }
  },
  computed: {
    description: function() {
      return polishPlurals("sztuka", "sztuki", "sztuk", this.number);
    }
  }
};
</script>
