<template>
  <div class="item__container">
    <div class="item__details">
      <div class="item__img-box" v-if="imageUrlExists">
        <img
          class="item__img"
          :src="imageAbsoluteUrl"
          :alt="item.product.name"
        />
      </div>
      <div class="item__description">
        <p class="item__title">{{ item.product.name }}</p>
        <p class="item__type">{{ item.product.type }}</p>
      </div>
    </div>
    <div class="item__summary">
      <div class="item__quantity">
        ilość: <strong><Quantity :number="item.quantity"/></strong>
      </div>
      <div class="item__counter">
        <span @click="decreaseQuantity()">-</span>
        <span @click="increaseQuantity()">+</span>
      </div>
      <div class="item__value" v-if="this.$parent.hasDiscounted">
        <strong class="item__value__price__striked">
          <FormattedPrice
            :price="
              item.price.strikedGross
                ? item.price.strikedGross
                : item.price.gross
            "
            :currency="item.price.currencyIsoCode"
          />
        </strong>

        <strong class="item__value__price">
          <FormattedPrice
            :price="item.price"
            :currency="item.price.currencyIsoCode"
          />
        </strong>
        <span v-if="isPln(item.price.currencyIsoCode)" class="item__tax"
          >(z VAT)</span
        >
      </div>

      <div class="item__value" v-else>
        <strong class="item__value__price">
          <FormattedPrice
            :price="
              item.price.strikedGross
                ? item.price.strikedGross
                : item.price.gross
            "
            :currency="item.price.currencyIsoCode"
          />
        </strong>
        <span v-if="isPln(item.price.currencyIsoCode)" class="item__tax"
          >(z VAT)</span
        >
      </div>
    </div>

    <Modal
      v-if="clearItemFromBasketModal"
      modalTitle="Usuń produkt"
      :modalSubtitle="modalMessage"
      modalOptionMain="Tak, usuń z koszyka"
      modalOptionSecond="Nie, zostaw w koszyku"
      v-on:selectModalOption="resolveClearItemFromBasketModal"
    />

    <Modal
      v-if="displaySubscriptionModal"
      modalTitle="Przedłuż prenumeratę"
      :modalSubtitle="modalEmissionMessage"
      modalOptionMain="TAK - przedłuż obecną prenumeratę"
      modalOptionSecond="NIE - kup od bieżącego numeru"
      v-on:selectModalOption="resolveExtendSubscriptionModal"
    />
  </div>
</template>

<script>
import Modal from "./Modal";
import Quantity from "../utils/Quantity";
import FormattedPrice from "../utils/FormattedPrice";
import { mapActions } from "vuex";

export default {
  name: "BaseItem",
  components: { FormattedPrice, Quantity, Modal },
  props: {
    item: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      default: "main"
    }
  },
  data() {
    return {
      imageAbsoluteUrl: this.item.product.imageUrl,
      clearItemFromBasketModal: false,
      displaySubscriptionModal: false,
      modalMessageTemplate: {
        default: `Czy usunąć produkt "${this.item.product.name}" z koszyka?`,
        mainHasDiscounted:
          "Ten produkt ma powiązane produkty w promocyjnej cenie. Po skasowaniu produktu głównego ich ceny stracą promocje. Czy chcesz skasować produkt?"
      }
    };
  },
  computed: {
    imageUrlExists: function() {
      return this.imageAbsoluteUrl != undefined && this.imageAbsoluteUrl != "";
    },
    modalMessage: function() {
      if (this.type === "main" && this.$parent.hasDiscounted === true) {
        return this.modalMessageTemplate.mainHasDiscounted;
      }
      return this.modalMessageTemplate.default;
    },
    modalEmissionMessage: function() {
      const dates = this.prepareOverlapsDate(this.item.overlaps.details);
      const productNames = this.prepareOverlapsProductNames(
        this.item.overlaps.details
      );
      return (
        "Twoja aktualna prenumerata " +
        productNames +
        " kończy się na numerze " +
        dates.lastOwnedEmissionName +
        ". Czy chcesz aby była kontynuowana od następnego numeru " +
        dates.nextEmissionDate +
        "?"
      );
    }
  },
  methods: {
    ...mapActions(["updateStartSubscriptionFromOwned"]),
    decreaseQuantity() {
      if (this.item.quantity === 1) {
        this.clearItemFromBasketModal = true;
      } else {
        this.$emit("decreaseQuantity", this.item, this.type);
      }
    },
    increaseQuantity() {
      this.$emit("increaseQuantity", this.item, this.type);
    },
    resolveClearItemFromBasketModal(value) {
      if (value === 1) {
        this.$emit("deleteItemFromBasket", this.item);
      }
      this.clearItemFromBasketModal = false;
    },
    async resolveExtendSubscriptionModal(value) {
      if (value === 1) {
        try {
          this.updateStartSubscriptionFromOwned(this.item.id);
          this.displaySubscriptionModal = false;
        } catch (error) {
          console.error(error);
        }
      } else {
        this.displaySubscriptionModal = false;
      }
    },
    checkDisplaySubscriptionModal() {
      if (this.item.overlaps && this.item.overlaps.occured === true) {
        this.displaySubscriptionModal = true;
      }
    },
    prepareOverlapsDate(date) {
      const lastOwnedEmissionName = date
        .map(item => item.lastOwnedEmissionName)
        .filter(Boolean);
      const splitedDate = lastOwnedEmissionName[0].split("/");
      const newDate = new Date(splitedDate[1], splitedDate[0]);
      const nextEmissionDate =
        String(newDate.getMonth() + 1) + "/" + String(newDate.getFullYear());
      return { lastOwnedEmissionName, nextEmissionDate };
    },
    prepareOverlapsProductNames(itemOverlapsDetails) {
      return itemOverlapsDetails
        .map(item => item.productName)
        .filter(Boolean)
        .join(", ");
    },
    isPln(currency) {
      return currency === "PLN";
    }
  },
  mounted() {
    this.checkDisplaySubscriptionModal();
  }
};
</script>
