<template>
  <div id="regulations-tems-form">
    <p class="rodo__check rodo__check--first">
      <label @click="checkAll()" class="rodo__check__label">
        <input v-model="markAll" type="checkbox" id="checkall-checkbox" />
        Zaznacz wszystkie zgody
        <span class="checkmark"></span>
      </label>
    </p>
    <p class="rodo__check">
      <label
        @click="$v.salesReg.$touch()"
        :class="
          $v.salesReg.$dirty && $v.salesReg.$invalid
            ? 'rodo__check__label coloredRed'
            : 'rodo__check__label'
        "
      >
        <input v-model="salesReg" type="checkbox" />
        Akceptuję
        <a
          href="https://prenumerata.murator.pl/warunki-sprzedazy/"
          class="text-underline"
          target="_blank"
          rel="noreferrer noopener"
        >
          regulamin sprzedaży
        </a>
        <span class="coloredRed">*</span>
        <span class="checkmark"></span>
      </label>
    </p>
    <p class="rodo__check rodo__txt">
      Zgadzam się na otrzymywanie od TIME S.A. informacji marketingowych, m.in.
      o nowościach, promocjach, rabatach, akcjach specjalnych:
    </p>
    <p class="rodo__check">
      <label class="rodo__check__label">
        <input v-model="newsletter" type="checkbox" />
        drogą elektorniczną (np. e-mail)
        <span class="checkmark"></span>
      </label>
    </p>
    <p class="rodo__check rodo__txt">
      Mam świadomość swojego prawa do odwołania zgody w każdym czasie, wysyłając
      e-mail na adres
      <a class="rodo__link" href="mailto:iod@grupazpr.pl">iod@grupazpr.pl</a>
      .
    </p>

    <p v-if="displayUsageRule" class="rodo__check">
      <label
        for="usage_rule"
        class="rodo__check__label usageRule"
        @click="$v.usageRule.$touch()"
        :class="
          $v.usageRule.$dirty && $v.usageRule.$invalid
            ? 'form__box__checkbox coloredRed'
            : 'form__box__checkbox'
        "
      >
        <input type="checkbox" id="usage_rule" v-model="usageRule" />
        Zgadzam się na eksploatację treści cyfrowych przed upływem 14-dniowego
        terminu do odstąpienia od umowy. Oznacza to możliwość korzystania z
        usług od razu po weryfikacji płatności i utratę prawa do odstąpienia od
        umowy.
        <span class="checkmark"></span>
      </label>
    </p>

    <p class="rodo__check rodo__informTitle">
      INFORMACJE PODSTAWOWE DOTYCZĄCE PRZETWARZANIA DANYCH OSOBOWYCH
    </p>
    <p class="rodo__check rodo__informTxt">
      <span class="rodo__underline">Administratorem</span> Twoich danych S.A. z
      siedzibą w Warszawie przy ul. Jubilerskiej 10, 04-190 Warszawa.
      <span class="rodo__underline">Cele przetwarzania</span> to realizacja
      usługi, podpisanie umowy oraz marketing własny TIME S.A., jeżeli wyraziłeś
      na to zgodę.
      <span class="rodo__underline">Podstawami prawnymi</span>
      przetwarzania są umowa sprzedaży/świadczenia usług oraz nasz prawnie
      uzasadniony interes. Odbiorcami danych są podmioty przetwarzające osobowe
      w naszym imieniu.
      <span class="rodo__underline">Twoje prawa</span> związane z przetwarzaniem
      danych: prawo sprzeciwu wobec przetwarzania danych w celach
      marketingowych, prawo dostępu do danych i inne prawa, o których mowa w
      szczegółowych informacjach o przetwarzaniu danych osobowych dostępnych
      <a
        class="rodo__link rodo__link--nu"
        href="https://rodo.grupazpr.pl/#time-klauzula-kliencka"
        target="_blank"
        >TUTAJ</a
      >.
    </p>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";

export default {
  name: "RegulationsTerms",
  mixins: [validationMixin],
  props: {
    displayUsageRule: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      markAll: false,
      salesReg: false,
      newsletter: false,
      usageRule: false
    };
  },
  validations: {
    salesReg: { assertTrue: val => val === true },
    usageRule: { assertTrue: val => val === true }
  },
  methods: {
    checkAll() {
      this.$v.$touch();
      if (!this.markAll) {
        this.salesReg = true;
        this.newsletter = true;
        this.usageRule = true;
      } else {
        this.salesReg = false;
        this.newsletter = false;
        this.usageRule = false;
      }
    }
  }
};
</script>
