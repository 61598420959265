<template>
  <div class="modal-overlay">
    <div class="modal">
      <h3 id="modal-title" class="modal__title">{{ modalTitle }}</h3>
      <p id="modal-subtitle" class="modal__subtitle">{{ modalSubtitle }}</p>
      <div class="modal__select">
        <button
          v-if="modalOptionMain"
          id="modal-option-main"
          class="modal__btn"
          @click="selectModalOption(1)"
        >
          {{ modalOptionMain }}
        </button>
        <button
          v-if="modalOptionSecond"
          id="modal-option-second"
          class="modal__btn"
          @click="selectModalOption(2)"
        >
          {{ modalOptionSecond }}
        </button>
        <div v-if="modalWaitOption" class="sk-chase">
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: {
    modalTitle: {
      type: String,
      required: true
    },
    modalSubtitle: {
      type: String,
      required: true
    },
    modalOptionMain: {
      type: String,
      required: false
    },
    modalOptionSecond: {
      type: String,
      required: false
    },
    modalWaitOption: {
      type: Boolean,
      required: false
    }
  },
  methods: {
    selectModalOption(value) {
      this.$emit("selectModalOption", value);
    }
  },
  mounted() {
    window.document.body.classList.add("bodyBlocked");
  },
  destroyed() {
    window.document.body.classList.remove("bodyBlocked");
  }
};
</script>

<style lang="scss" scoped>
.modal-overlay {
  position: fixed;
  top: 86px;
  left: 0;
  z-index: 100;
  height: 100%;
  width: 100%;
  background-color: rgba(243, 243, 243, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  position: relative;
  top: -86px;
  width: 320px;
  padding: 16px 12px;
  border-radius: 14px;
  background-color: $lightBgColor;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.modal {
  &__title {
    text-align: center;
    font-weight: 600;
    margin-bottom: 8px;
    font-size: 17px;
    line-height: 22px;
  }

  &__subtitle {
    text-align: center;
    margin-bottom: 10px;
    font-size: 13px;
    line-height: 16px;
  }

  &__select {
    display: flex;
    flex-direction: column;
  }

  &__btn {
    padding: 10px 0;
    color: $modalColor;
    background: none;
    outline: none;
    border: none;
    font-family: $fontPrimary;
    cursor: pointer;
    font-size: 17px;
    line-height: 22px;
    transition: all 0.2s;

    &:hover {
      font-weight: 600;
      text-shadow: 0px 4px 15px #9cc4ef;
    }
  }

  &__btn:first-of-type {
    font-weight: 600;
  }
}

.sk-chase {
  width: 40px;
  height: 40px;
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
  margin: 10px auto;
}

.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: sk-chase-dot 2s infinite ease-in-out both;
}

.sk-chase-dot:before {
  content: "";
  display: block;
  width: 25%;
  height: 25%;
  background-color: #000000;
  border-radius: 100%;
  animation: sk-chase-dot-before 2s infinite ease-in-out both;
}

@for $i from 1 through 6 {
  .sk-chase-dot:nth-child(#{$i}) {
    animation-delay: -1.2s + 0.1 * $i;
  }
}

@for $i from 1 through 6 {
  .sk-chase-dot:nth-child(#{$i}):before {
    animation-delay: -1.2s + 0.1 * $i;
  }
}

@keyframes sk-chase {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot {
  80%,
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4);
  }
  100%,
  0% {
    transform: scale(1);
  }
}
</style>
