<template>
  <div class="company__order_info___container">
    <div class="company__order_info__icon"></div>
    <div class="company__order_info__text">
      Jeśli chcesz założyć zamówienie w ramach działalności gospodarczej prosimy
      o kontakt z Biurem Obsługi Klienta
    </div>
    <div class="company__order_info__phone">
      <b>telefon:</b> 22 59 05 555 (czynny w dni powszednie w godz. 8-16)
    </div>
    <div class="company__order_info__mail">
      <b>mail:</b> <a href="mailto:klienci@grupazpr.pl">klienci@grupazpr.pl</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompanyOrderInfo"
};
</script>

<style lang="scss" scoped>
.company__order_info___container {
  border: 1px solid #fbb040;
  padding: 20px 20px 20px 60px;
  border-radius: 8px;
  margin-top: 24px;
  position: relative;
}
.company__order_info__text,
.company__order_info__mail,
.company__order_info__phone {
  color: #4f4f4f;
  font-size: 14px;
  margin-bottom: 16px;
  line-height: 18px;
}
.company__order_info__mail a {
  color: #3ec6ff;
}
.company__order_info___container:before {
  content: "";
  background: url(https://cdn.files.smcloud.net/t/alert-triangle.svg);
  position: absolute;
  top: 40%;
  width: 30px;
  height: 27px;
  left: 4%;
  background-size: contain;
  background-repeat: no-repeat;
  pointer-events: none;
  @include lg {
    left: 2%;
  }
}
</style>
