<template>
  <div id="login-page">
    <Header />
    <Steps />

    <ErrorBox :errors="loginErrors" ref="errorBox" />

    <div class="loginBox">
      <div class="container col1-2">
        <div class="login">
          <p class="login__title">Mam konto do zakupów</p>

          <div
            :class="
              ($v.email.$dirty && $v.email.$invalid) || $v.errors.$invalid
                ? 'form__box form__box--error'
                : 'form__box'
            "
          >
            <p class="input__title">Adres e-mail</p>
            <div
              :class="
                $v.email.$invalid || $v.errors.$invalid
                  ? 'form__inputBox'
                  : 'form__inputBox form__inputBox--valid'
              "
            >
              <input
                v-model="email"
                @blur="$v.email.$touch"
                @keyup.enter="logUserIn()"
                type="email"
                class="form__input"
                placeholder="podaj adres e-mail"
              />
            </div>
            <p v-if="!$v.email.required" class="input__error">
              Podaj adres email
            </p>
            <p v-if="!$v.email.email" class="input__error">
              Podaj poprawny adres email
            </p>
          </div>

          <div
            :class="
              ($v.password.$dirty && $v.password.$invalid) || $v.errors.$invalid
                ? 'form__box form__box--error'
                : 'form__box'
            "
          >
            <p class="input__title">Hasło</p>
            <div
              :class="
                $v.password.$invalid || $v.errors.$invalid
                  ? 'form__inputBox'
                  : 'form__inputBox form__inputBox--valid'
              "
            >
              <input
                v-model="password"
                @blur="$v.password.$touch"
                @keyup.enter="logUserIn()"
                type="password"
                class="form__input"
                placeholder="hasło powinno zawierać 6 znaków"
              />
            </div>
            <p v-if="!$v.password.required" class="input__error">Podaj hasło</p>
          </div>

          <div class="pswdReset">
            <p
              id="reset-pass-btn"
              class="pswdReset__title"
              @click="resetPassword()"
            >
              Zapomniałem hasła
            </p>
          </div>

          <button
            id="login-btn"
            :class="$v.$invalid ? 'btn btn--inactive' : 'btn btn--primary'"
            @click="logUserIn()"
          >
            Zaloguj się
          </button>
          <router-link class="btn btn--secondaryLight" to="/" tag="button">
            Wróć do zakupów
          </router-link>
        </div>
      </div>
      <div
        id="toggle-login-register"
        class="container container--register col1-2"
      >
        <div class="register-option">
          <div class="register-option__btnBox">
            <ToggleLoginRegister />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email, maxLength } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import redirectMixin from "../mixins/redirectMixin";
import Header from "../components/basket/Header";
import Steps from "../components/checkout/Steps";
import ErrorBox from "../components/basket/ErrorBox";
import ToggleLoginRegister from "../components/checkout/ToggleLoginRegister";

export default {
  name: "Login",
  mixins: [validationMixin, redirectMixin],
  components: { ErrorBox, Header, Steps, ToggleLoginRegister },
  data() {
    return {
      email: "",
      password: "",
      loginErrors: []
    };
  },
  validations: {
    email: { required, email },
    password: { required },
    errors: { maxLength: maxLength(0) }
  },
  computed: {
    ...mapGetters(["isBasketOverlapsOccured", "getAccountUrl"])
  },
  methods: {
    ...mapActions(["loginCustomer", "loadBasketData"]),
    async logUserIn() {
      this.$v.$touch();
      this.loginErrors = [];

      if (this.$v.$invalid) {
        return;
      }

      try {
        await this.loginCustomer({
          login: this.email,
          password: this.password
        });
      } catch (error) {
        this.loginErrors.push(error.message);
        this.$refs.errorBox.$el.scrollIntoView({ behavior: "smooth" });
        return;
      }

      try {
        await this.loadBasketData();
      } catch (error) {
        console.error(error);
      }

      if (this.isBasketOverlapsOccured) {
        this.redirectToProductList();
      } else {
        this.redirectToOrder();
      }
    },
    redirectToProductList() {
      this.$router.push("/");
    },
    resetPassword() {
      let tab = window.open(
        this.getAccountUrl + "/przypomnienie-hasla",
        "_blank"
      );
      tab.focus();
    }
  }
};
</script>

<style lang="scss" scoped>
.login {
  background-color: $lightBgColor;
  padding: 14px 8px;
  width: 100%;

  @include lg {
    padding: 40px 24px 24px 24px;
  }

  &__title {
    font-size: 18px;
    line-height: 21px;

    @include lg {
      font-size: 22px;
      line-height: 24px;
      font-weight: 500;
    }
  }
  &Box {
    @include lg {
      display: flex;
    }

    .col1-2 {
      @include lg {
        width: 406px;

        &:first-of-type {
          margin-right: 4px;
        }
        &:last-of-type {
          margin-left: 4px;
        }
      }
    }

    .btn--secondaryLight {
      margin-top: 16px;

      @include lg {
        margin-top: 24px;
      }
    }

    .register-option__btnBox {
      .btn--secondaryLight {
        margin-top: 0;
      }
    }
  }

  .form {
    &__box {
      @include lg {
        margin-top: 34px;
      }

      &:first-of-type {
        @include lg {
          margin-top: 24px;
        }
      }
    }
  }
}

.errors {
  width: 100%;
  background-color: $errorColor;
  padding: 14px 8px;
  margin-bottom: 16px;

  &__text {
    @include font-sm;
    color: $lightBgColor;
  }
}

.pswdReset {
  &__title {
    font-size: 18px;
    line-height: 21px;
    color: #828282;
    text-align: center;
    text-decoration: underline;
    font-weight: 300;
    margin: 8px 0 16px 0;
    cursor: pointer;

    @include lg {
      margin: 24px 0;
    }
  }
}

.loginBox .register-option {
  @include lg {
    padding: 40px 24px 24px 24px;
  }
}
</style>
