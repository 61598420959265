import Vue from "vue";
import App from "./App.vue";
// import Raven from "raven-js";
// import RavenVue from "raven-js/plugins/vue";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;
// WYŁACZENIE SENTRY Z POWODU CORS
// const sentryUrl = process.env.VUE_APP_SENTRY;
// const sentryConfig = {
//   headers: { "X-Sentry-Token": process.env.VUE_APP_SENTRY_SECRET }
// };
// if ((sentryUrl, sentryConfig)) {
//   Raven.config(sentryUrl)
//     .addPlugin(RavenVue, Vue)
//     .install();
// }

function setService() {
  const service = {};
  const defaultService = "wiemiwybieram.pl";
  const serviceDomainWrapper = document.getElementById("service_domain");
  if (serviceDomainWrapper) {
    const domain = serviceDomainWrapper.dataset.domain;
    const name = serviceDomainWrapper.dataset.name;
    const retUrl = serviceDomainWrapper.dataset.retUrl;
    const accountUrl = serviceDomainWrapper.dataset.accountUrl;
    service["domain"] = domain;
    service["name"] = name ? name : domain;
    service["retUrl"] = retUrl ? retUrl : `https://${domain}`;
    service["accountUrl"] = accountUrl
      ? accountUrl
      : `https://konto.wiemiwybieram.pl`;
  } else {
    service["domain"] = defaultService;
    service["name"] = defaultService;
    service["retUrl"] = `https://${defaultService}`;
    service["accountUrl"] = `https://konto.wiemiwybieram.pl`;
  }
  store.commit("SET_SERVICE", service);
}

function setRefferer() {
  const refererLabelWrapper = document.getElementById("referer_label");
  if (refererLabelWrapper) {
    store.commit("SET_REFERER_LABEL", refererLabelWrapper.dataset.refererLabel);
  }
}

function setBackReferrerUrl() {
  store.commit("SET_BACK_TO_SHOPPING_URL", document.referrer);
}

setRefferer();
setService();
setBackReferrerUrl();
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
