<template>
  <div class="container__order container__order--user" v-if="getCustomerEmail">
    <p class="form__box__title">
      <span v-if="isUserNoRegister">Użytkownik bez rejestracji</span>
      <span v-else>Użytkownik zalogowany</span>
    </p>
    <div class="form__box__user">
      <div>
        <p class="user__mail">
          <span>{{ getCustomerEmail }}</span
          ><span class="user__account">{{ accountLabel }} </span>
        </p>
      </div>
      <p class="user__mailChange" @click="changeUser()">wyloguj</p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "customerEmail",
  computed: {
    ...mapGetters(["getCustomerEmail", "isUserNoRegister", "getUserType"]),
    accountLabel: function() {
      if (this.getUserType == null) return "";
      return this.getUserType === "Company"
        ? "(konto firmowe)"
        : "(konto prywatne)";
    }
  },
  methods: {
    ...mapActions([
      "logOut",
      "clearUserData",
      // "removeRabateCode",
      "clearBasketItems"
    ]),
    changeUser() {
      // this.clearBasketItems();
      try {
        this.logOut();
      } catch (error) {
        console.error(error);
      }
      this.redirectToBasket();
    },
    redirectToBasket() {
      this.$router.push({ name: "Basket" });
    }
  }
};
</script>

<style lang="scss" scoped>
.form__box {
  &__title {
    @include font-xl;
    color: #181818;
    margin-bottom: 16px;

    @include lg {
      font-weight: 500;
      margin-bottom: 24px;
      padding-bottom: 16px;
      border-bottom: 1px solid #f4f4f4;
    }
  }
  &__user {
    display: flex;
    justify-content: space-between;
  }
}

.user {
  &__mail {
    color: #3ec6ff;
    @include font-xl-n;
    @include lg {
      font-size: 16px;
      line-height: 146%;
    }

    &Change {
      color: #505050;
      font-size: 11px;
      line-height: 14px;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__account {
    color: #505050;
    font-size: 11px;
    line-height: 14px;
    margin-left: 6px;
  }
}
</style>
