<template>
  <ErrorBox :errors="text" isBulletin />
</template>
<script>
import ErrorBox from "../basket/ErrorBox";

export default {
  components: { ErrorBox },
  data: function() {
    return {
      text: []
    };
  },
  beforeMount() {
    const date = new Date();
    const bulletinStart = new Date(process.env.VUE_APP_GLOBAL_BULLETIN_START);
    const bulletinEnd = new Date(
      process.env.VUE_APP_GLOBAL_BULLETIN_END + "T23:59:59"
    );
    if (bulletinStart <= date && date <= bulletinEnd) {
      this.text = [process.env.VUE_APP_GLOBAL_BULLETIN];
    }
  }
};
</script>
