<template>
  <div class="order-confirm">
    <Header />
    <Steps />
    <div class="container">
      <div class="col2-3">
        <div class="greeting">
          <p class="greeting__text greeting__title">
            Dziękujemy za zakup produktów
          </p>
          <div
            class="greeting__text greeting__description"
            v-if="thankYouPageTypeKey.deliveryDescription == 'default'"
          >
            <p>Dostawa:</p>
            <p class="greeting__text greeting__delivery_option">
              Elektroniczna:
              <span>
                produkt będzie dostępny na twoim koncie natychmiast po
                zaksięgowaniu wpłaty
              </span>
            </p>
            <p class="greeting__text greeting__delivery_option">
              Poczta:
              <span>
                3-10 dni roboczych
              </span>
            </p>
            <p class="greeting__text greeting__delivery_option">
              Kurier:
              <span>
                1-4 dni robocze
              </span>
            </p>
          </div>
          <div
            class="greeting__text greeting__description"
            v-if="
              thankYouPageTypeKey.deliveryDescription != '' &&
                thankYouPageTypeKey.deliveryDescription != 'default'
            "
          >
            <p
              class="greeting__text greeting__delivery_option"
              v-if="thankYouPageTypeKey.deliveryOption != ''"
            >
              Dostawca: <span>{{ thankYouPageTypeKey.deliveryOption }}</span>
            </p>
            <p
              v-if="
                thankYouPageTypeKey.deliveryOption != '' ||
                  (cashTransferData != null && cashTransferData.amount != 0)
              "
            >
              {{ thankYouPageTypeKey.deliveryDescription }}
            </p>
            <p v-else>
              Twoje produkty są dostępne na Twoim koncie
            </p>
            <p class="greeting__text greeting__info">
              Więcej informacji na temat dostawy znajdziesz w dziale
              <a
                href="https://www.wiemiwybieram.pl/artykul/najczesciej-zadawane-pytania-aa-e5Bu-s22T-FxKD/"
                >Pomoc i pytania</a
              >
            </p>
          </div>
        </div>
        <div
          v-if="cashTransferData != null && cashTransferData.amount != 0"
          class="payment"
        >
          <div v-if="thankYouPageTypeKey.payment != ''" class="payment__info">
            <p class="payment__txt payment__txt--title">Płatność</p>
            <p
              v-if="thankYouPageTypeKey.payment != 'default'"
              class="payment__txt payment__txt--payment"
            >
              {{ thankYouPageTypeKey.payment }}
            </p>
            <p
              v-if="thankYouPageTypeKey.orderStatus == 'PAYMENT_COMPLETED'"
              class="payment__txt payment__txt--status"
            >
              Status płatności:
              <span class="payment__txt payment__txt--completed"
                >Płatność zakończona</span
              >
            </p>
            <p
              v-if="thankYouPageTypeKey.orderStatus == 'CASH_ON_DELIVERY'"
              class="payment__txt payment__txt--cash-on-delivery"
            >
              Kwotę w wysokości
              <FormattedPrice
                :price="cashTransferData.amount"
                :currency="cashTransferData.currency"
              />należy opłacić u kuriera.
            </p>
          </div>

          <div
            v-if="
              thankYouPageTypeKey.orderStatus == 'CASH_TRANSFER' ||
                thankYouPageTypeKey.payment == 'default'
            "
            class="payment__account"
          >
            <p
              v-if="thankYouPageTypeKey.payment == 'default'"
              class="payment__account__title"
            >
              Jeżeli wybrałeś płatność przelewem tradycyjnym prosimy o dokonanie
              wpłaty na konto:
            </p>
            <p v-else class="payment__account__title">
              Prosimy o dokonanie wpłaty na konto:
            </p>
            <p class="payment__account__title">
              Numer konta:
              <span class="payment__account__txt">
                27 1240 6292 1111 0010 9049 2058
              </span>
            </p>
            <p class="payment__account__title">
              Odbiorca:
              <span class="payment__account__txt">
                Time S.A.
              </span>
            </p>
            <p class="payment__account__title">
              Adres:
              <span class="payment__account__txt">
                ul. Jubilerska 10, 04-190 Warszawa
              </span>
            </p>
            <p class="payment__account__title">
              Tytuł:
              <span class="payment__title__number">
                Nr. {{ cashTransferData.orderId }}
              </span>
            </p>
            <p class="payment__account__title">
              Kwota:
              <span class="payment__account__price"
                ><FormattedPrice
                  :price="cashTransferData.amount"
                  :currency="cashTransferData.currency"
              /></span>
            </p>
            <p
              v-if="thankYouPageTypeKey.payment == 'default'"
              class="payment__account__title"
            >
              Płatność za pobraniem
              <span class="payment__account__txt">
                należy uiścić u kuriera
              </span>
            </p>
          </div>
        </div>

        <div
          class="order-confirm__items confirm-items"
          v-if="getOrdersItems.length > 0"
        >
          <p class="confirm-items__title">
            Zamówione produkty
          </p>

          <ul class="confirm-items__list">
            <li class="item" v-for="item in getOrdersItems" :key="item.id">
              <div class="item__details">
                <div
                  class="item__img-box"
                  v-if="
                    item.product.imageUrl != undefined &&
                      item.product.imageUrl != ''
                  "
                >
                  <!--                  <a-->
                  <!--                    :href="item.product.url"-->
                  <!--                    class="item__link item__link&#45;&#45;img"-->
                  <!--                    target="_blank"-->
                  <!--                  >-->
                  <img
                    class="item__img"
                    :src="item.product.imageUrl"
                    :alt="item.product.name"
                  />
                  <!--                  </a>-->
                </div>
                <div class="item__description">
                  <!--                  <a-->
                  <!--                    :href="item.product.url"-->
                  <!--                    class="item__link item__link&#45;&#45;title"-->
                  <!--                    target="_blank"-->
                  <!--                  >-->
                  <p class="item__title">
                    {{ item.product.name }}
                  </p>
                  <!--                  </a>-->

                  <div
                    class="item__buttons"
                    v-if="thankYouPageTypeKey.orderStatus != 'CASH_TRANSFER'"
                  >
                    <!--
                      <a
                        href="#"
                        target="_blank"
                        class="item__button item__button--download"
                      >
                        Pobierz
                      </a>
                    -->
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div
          v-if="isTransactionContainsPhysicals && deliveryAddress != null"
          class="delivery__address"
        >
          <p class="delivery__address__title">Adres dostawy</p>
          <p class="delivery__address__info">Dane odbiorcy:</p>
          <p class="delivery__address__name">
            {{ deliveryAddress.firstName }} {{ deliveryAddress.lastName }}
          </p>
          <p
            class="
            delivery__address__street"
          >
            {{ deliveryAddress.street }}
          </p>
          <p class="delivery__address__postal_code">
            {{ deliveryAddress.postalCode }} {{ deliveryAddress.city }}
          </p>
          <p class="delivery__address__country">
            {{ deliveryAddress.country }}
          </p>
          <p
            class="
            delivery__address__phone"
            v-if="deliveryAddress.phone != null"
          >
            {{ deliveryAddress.phone }}
          </p>
        </div>
      </div>
      <div class="col1-3">
        <div class="buttons">
          <Modal
            v-if="registerSuccessModal"
            modalTitle="Rejestracja udana"
            modalSubtitle="Posiadasz teraz dostęp do zakupionych produktów na konto.wiemiwybieram.pl."
            modalOptionMain="Tak, przejdź do konta"
            modalOptionSecond="Nie, zostań w sklepie"
            v-on:selectModalOption="resolveRegisterSuccessModal"
          />

          <div
            v-if="isUserNoRegister && !customerWasRegistered"
            class="buttons__container"
          >
            <p class="buttons__title">Rejestracja</p>
            <p class="buttons__description">
              Rejestracja pozwoli Ci w pełni korzystać z zakupionych produktów.
            </p>
            <p class="buttons__description">
              Do założnia konta wystarczy twój e-maila i hasło do nowego konta.
            </p>
            <RegisterForm
              ref="registerForm"
              v-on:registeredCustomer="resolveRegisterCustomer"
            >
              <p class="buttons__title-no-img">Zgody prawne i marketingowe</p>
            </RegisterForm>
            <button
              class="btn btn--primary"
              @click="$refs.registerForm.checkFormAndTryRegisterCustomer()"
            >
              Zarejestruj się
            </button>
          </div>

          <div v-else class="buttons__container">
            <p class="buttons__title">Twoje konto</p>
            <div class="buttons__description__container">
              <p class="buttons__description">
                Historia Twoich zakupów dostępna jest w zakładce "Moje
                zamówienia" na Twoim koncie
              </p>
            </div>
            <button class="btn btn--primary" @click="redirectToUserAccount">
              Przejdź do konta
            </button>
          </div>
          <div class="buttons__container buttons__container--noBg">
            <button
              @click="redirectToBackToShoppingUrl"
              class="btn btn--secondary"
            >
              Wróć do sklepu
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Header from "../components/basket/Header";
import Modal from "../components/basket/Modal";
import Steps from "../components/checkout/Steps";
import RegisterForm from "../components/checkout/RegisterForm";
import FormattedPrice from "../components/utils/FormattedPrice";

export default {
  name: "OrderConfirm",
  components: {
    Header,
    Steps,
    Modal,
    RegisterForm,
    FormattedPrice
  },
  data() {
    return {
      registerSuccessModal: false,
      customerWasRegistered: false,
      cashTransferData: {
        amount: 0,
        orderId: null,
        currency: "PLN"
      },
      thankYouPageTypeKey: {
        payment: "",
        deliveryDescription: "",
        deliveryOption: "",
        orderStatus: ""
      },
      deliveryAddress: null
    };
  },
  computed: {
    ...mapGetters([
      "getBackToShoppingUrl",
      "getOrdersItems",
      "getDataForCashTransfer",
      "isUserNoRegister",
      "getAccountUrl",
      "getThankYouPageTypeKey",
      "isTransactionContainsPhysicals",
      "getDeliveryAddress"
    ])
  },
  methods: {
    ...mapActions(["getOrdersByTransaction", "loadCustomerData"]),
    resolveRegisterCustomer(value) {
      if (value === true) {
        this.registerSuccessModal = true;
        this.customerWasRegistered = true;
      }
    },
    resolveRegisterSuccessModal(value) {
      if (value === 1) {
        this.registerSuccessModal = false;
        this.redirectToUserAccount();
      }
      if (value === 2) {
        this.registerSuccessModal = false;
      }
    },
    redirectToBackToShoppingUrl() {
      window.location.href = this.getBackToShoppingUrl;
    },
    userAccountUrl() {
      return this.getAccountUrl + "/moje-zamowienia";
    },
    redirectToUserAccount() {
      window.location.href = this.userAccountUrl();
    }
  },
  async mounted() {
    try {
      await this.loadCustomerData();
    } catch (error) {
      console.error(error.message);
    }

    try {
      await this.getOrdersByTransaction();
    } catch (error) {
      console.error(error.message);
    }

    this.cashTransferData = this.getDataForCashTransfer;
    this.thankYouPageTypeKey = this.getThankYouPageTypeKey;
    this.deliveryAddress = this.getDeliveryAddress;
  }
};
</script>

<style lang="scss" scoped>
.payment {
  margin-bottom: 16px;
  padding: 12px 8px 16px;
  background-color: #fff;

  @include lg {
    padding: 12px 16px 16px;
  }

  &__txt {
    font-size: 16px;

    &--title {
      margin-bottom: 12px;
      font-weight: 500;
      padding-left: 0;
      padding-bottom: 16px;
      font-size: 18px;
      line-height: 24px;
      font-weight: 500;
      border-bottom: 1px solid #f4f4f4;
    }
    &--status {
      font-weight: 400;
      span {
        font-weight: 500;
      }
    }
    &--info {
      margin-bottom: 40px;
    }
    &--payment {
      margin-bottom: 24px;
    }
  }
  &__info,
  &__account,
  &__name,
  &__title,
  &__price {
    margin-bottom: 16px;
  }

  &__name {
    display: flex;

    &__adress {
      &--row {
        display: block;
      }
    }
  }

  &__account__number,
  &__price__number {
    font-weight: 700;
  }

  &__title {
    &__number {
      @include lg {
        font-weight: 700;
      }
    }
  }
}

.payment__account {
  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    span {
      font-weight: 400;
    }
  }
}
.delivery__address {
  padding: 16px 16px 24px 16px;
}
.delivery__address__title {
  padding-left: 0;
  padding-bottom: 16px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  border-bottom: 1px solid #f4f4f4;
}

.delivery__address {
  background: white;
}
.delivery__address p {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
.delivery__address p.delivery__address__title {
  padding-left: 0;
  padding-bottom: 16px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  border-bottom: 1px solid #f4f4f4;
  margin-bottom: 16px;
}

.delivery__address p.delivery__address__info {
  font-weight: 500;
  line-height: 19px;
}
.greeting {
  margin-bottom: 16px;
  padding: 12px 12px 0px;
  background-color: $lightBgColor;

  @include lg {
    padding: 16px 16px 0px;
  }

  &__text {
    font-size: 18px;
    line-height: 21px;

    @include lg {
      font-size: 16px;
    }
  }

  &__title {
    position: relative;
    margin-bottom: 16px;
    padding-bottom: 16px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    @include borderHrLine;
    @include lg {
      padding-left: 0;
    }
  }

  &__description {
    min-height: 28px;
    padding-left: 52px;
    background: url("https://cdn.files.smcloud.net/t/check.svg") no-repeat;
    padding-bottom: 16px;
    @include lg {
      padding-left: 58px;
      padding-bottom: 40px;
    }
  }
}
.greeting__text.greeting__delivery_option {
  font-weight: 500;
  margin-bottom: 24px;
  span {
    font-weight: 400;
  }
}
.greeting__text.greeting__info {
  margin-top: 24px;
  font-size: 14px;
  a {
    color: #3ec6ff;
    display: inline-block;
    @include lg {
      display: block;
    }
  }
}
.confirm-items {
  padding-top: 12px;
  margin-bottom: 16px;
  background-color: $lightBgColor;
  padding: 12px 8px;
  @include lg {
    padding: 16px 16px;
  }

  &__title {
    padding-left: 0;
    padding-bottom: 16px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    border-bottom: 1px solid #f4f4f4;
  }
  &__list {
    list-style-type: none;
  }
}
.confirm-items__list .item:after {
  display: none;
}
.item {
  position: relative;
  padding: 12px;
  margin-bottom: 12px;
  background-color: $lightBgColor;
  @include lg {
    padding: 16px 0 24px;
    display: flex;
    margin-bottom: 0;
  }

  &__details {
    display: flex;

    @include lg {
      flex: 1;
    }
  }

  &__img-box {
    width: 67px;
    height: 83px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__img {
    max-width: 100%;
    max-height: 100%;
  }

  &__description {
    flex: 1;
    padding: 0 12px;
  }

  &__link {
    text-decoration: none;
    color: inherit;
  }

  &__title {
    font-size: 16px;
    font-weight: 500;
  }

  &__buttons {
    text-align: right;
  }

  &__button {
    display: inline-block;
    margin-left: 12px;
    padding: 10px 10px 10px 30px;
    font-size: 12px;
    line-height: 14px;
    text-decoration: none;
    color: #000;
    border: 2px solid #dddddd;
    transition: all 0.3s;

    &:first-child {
      margin-left: 0;
    }

    &:hover {
      background-color: #dddddd;
    }

    &--read {
      background: url("./../assets/read.svg") 8px 8px no-repeat;
    }

    &--download {
      background: url("./../assets/download.svg") 10px 12px no-repeat;
    }
  }
}
.buttons__description__container {
  margin-bottom: 16px;
}
.buttons {
  background: white;
  &__container {
    padding: 12px 8px 0px;
    background-color: $lightBgColor;

    @include lg {
      padding: 16px 16px 0px;
    }

    &--noBg {
      background-color: inherit;
    }
  }

  &__title {
    padding-left: 0;
    padding-bottom: 16px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    border-bottom: 1px solid #f4f4f4;
    margin-bottom: 24px;
  }

  &__title-no-img {
    margin-top: 30px;
    margin-bottom: 12px;
    font-size: 22px;
    line-height: 24px;

    @include lg {
      margin-bottom: 16px;
      font-size: 20px;
      line-height: 22px;
    }
  }

  &__description {
    margin-bottom: 24px;
    font-size: 16px;
    color: #4f4f4f;
    font-weight: 500;
  }
}
.buttons .btn--secondary {
  border: none;
  box-shadow: none;
  padding-bottom: 20px;
}
.buttons__description__info {
  font-size: 16px;
  a {
    color: #3ec6ff;
  }
}
.payment__txt.payment__txt--cash-on-delivery {
  font-weight: 500;
}
</style>
