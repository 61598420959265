<template>
  <li class="item">
    <BaseItem
      :item="item"
      v-on:increaseQuantity="resolveIncreaseQuantity"
      v-on:decreaseQuantity="resolveDecreaseQuantity"
      v-on:deleteItemFromBasket="resolveDeleteItemFromBasket"
    />
  </li>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import BaseItem from "./BaseItem";

export default {
  name: "Item",
  components: { BaseItem },
  props: {
    item: Object
  },
  data() {
    return {
      productCode: this.item.product.code,
      clearItemFromBasketModal: false
    };
  },
  computed: {
    ...mapGetters(["getService"])
  },
  methods: {
    ...mapActions([
      "changeItemQuantity",
      "deleteItemFromBasket",
      "deleteNestedItemFromBasket",
      "loadBasketData",
      "addProductToBasketWithProductCodeOrIncreaseQuantity"
    ]),
    resolveIncreaseQuantity(item, itemType) {
      this.changeQuantityForItem(item, itemType, "increase");
    },
    resolveDecreaseQuantity(item, itemType) {
      this.changeQuantityForItem(item, itemType, "decrease");
    },
    async changeQuantityForItem(item, itemType, direction) {
      try {
        await this.changeItemQuantity([item, direction]);
      } catch (error) {
        console.error(error);
      }
    },
    async resolveDeleteItemFromBasket(item) {
      let loadBasketAfterDeleteMainItem = false;

      try {
        await this.deleteItemFromBasket(item);
      } catch (error) {
        loadBasketAfterDeleteMainItem = false;
        console.error(error);
      }

      if (loadBasketAfterDeleteMainItem) {
        try {
          await this.loadBasketData();
        } catch (error) {
          console.error(error);
        }
      }
    },
    async resolveAddProductToBasket(product) {
      try {
        await this.addProductToBasketWithProductCodeOrIncreaseQuantity({
          productCode: product.productCode,
          mainProductCode: this.productCode
        });
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>

<style lang="scss">
.item {
  margin-bottom: 16px;
  background-color: $lightBgColor;
  &__container {
    display: flex;
    flex-direction: column;
    padding: 15px 0;

    @include lg {
      flex-direction: row;
      align-items: center;
    }
  }

  @include lg {
    position: relative;
    padding: 24px 0;
    display: flex;
    margin-bottom: 0;
    flex-direction: column;
  }

  &__details {
    display: flex;
    padding-left: 8px;

    @include lg {
      flex: 1;
    }
  }

  &__img-box {
    width: 67px;
    height: 83px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  &__img {
    max-width: 100%;
    max-height: 100%;
    font-size: 13px;
    line-height: 14px;
  }

  &__description {
    flex: 1;
    padding: 0 12px;
  }

  &__link {
    text-decoration: none;
    color: inherit;
  }

  &__title {
    margin-bottom: 20px;
    @include font-md;

    @include lg {
      margin-bottom: 8px;
      font-weight: 500;
    }
  }

  &__type {
    @include font-sm;
  }

  &__summary {
    display: flex;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;
    position: relative;
    @include lg {
      flex-direction: column;
      justify-content: center;
      padding-right: 0;
      align-items: flex-end;
    }
  }
  &__remove {
    color: #979797;
    text-decoration: underline;
    font-size: 18px;
    line-height: 27px;
    height: 25px;
    position: relative;
    bottom: 2px;
    text-align: right;
    cursor: pointer;
    @include lg {
      width: 100%;
      bottom: 10px;
    }
  }
  &__quantity {
    margin-right: 11px;
    @include font-md;

    @include lg {
      margin-right: 0;
      margin-bottom: 16px;
      font-size: 14px;
      line-height: 16px;
    }
  }

  &__counter {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 16px;
    margin-bottom: 8px;
    @include lg {
      margin-bottom: 16px;
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 38px;
      height: 38px;
      text-align: center;
      font-size: 28px;
      line-height: 38px;
      color: #dddddd;
      border: 2px solid #dddddd;
      transition: all 0.3s;
      cursor: pointer;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &:hover {
        background-color: #dddddd;
      }
    }
  }

  &__value {
    margin-left: auto;
    padding-bottom: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @include md {
      @include font-lg;
      display: block;
    }

    @include lg {
      font-size: 18px;
      line-height: 21px;
      display: block;
    }
  }

  &__tax {
    margin-left: 6px;
    @include font-sm;
    font-weight: 300;
  }
}
strong.item__value__price__striked {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-decoration-line: line-through;
  color: #505050;
  @include lg {
    padding-right: 17px;
  }
}

li.item:after {
  @include lg {
    content: "";
    height: 16px;
    width: 664px;
    background: #f4f4f4;
    position: absolute;
    bottom: 0;
    margin-left: -12px;
  }
}
.item__container:not(:last-child) {
  border-bottom: 1px solid #c4c4c4;
}
// .item__img-box img[src="http://212.180.241.25:7201/covers/no_image.png"]::after {
//     content: '';
//     position: absolute;
//     width: 100%;
//     height: 100%;
//     background: black;
//     left: 0;
//     top: 0;
// }
</style>
