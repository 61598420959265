<template>
  <div :class="basket_class">
    <Header />
    <div class="container">
      <div class="col2-3">
        <ItemList />
        <div v-if="isBasketEmpty" class="empty-basket">
          <p class="empty-basket__title">Twój koszyk jest pusty</p>
          <p class="empty-basket__text">
            Zrób zakupy w naszym sklepie {{ getService.name }}.
          </p>
        </div>
      </div>
      <div class="col1-3">
        <Summary :deliveryDisplay="isDeliveryChosen" v-if="!isBasketEmpty" />
        <Buttons />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../components/basket/Header";
import ItemList from "../components/basket/ItemList";
import Summary from "../components/basket/Summary";
import Buttons from "../components/basket/Buttons";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Basket",
  components: { Buttons, Summary, ItemList, Header },
  computed: {
    ...mapGetters(["isBasketEmpty", "getService", "isDeliveryChosen"]),
    basket_class: function() {
      if (this.isBasketEmpty) return "basket basket--cleared";
      else return "basket";
    }
  },
  methods: {
    ...mapActions([
      "loadBasketData",
      "setBackToShoppingUrl",
      "gtmCheckoutEvent",
      "setIpAddress"
    ]),
    getReferrerOfDocument() {
      return document.referrer;
    }
  },
  async mounted() {
    this.setBackToShoppingUrl(this.getReferrerOfDocument());
    if (this.isBasketEmpty) {
      await this.loadBasketData();
    }
    this.gtmCheckoutEvent();
    await this.setIpAddress();
  }
};
</script>

<style lang="scss" scoped>
.container {
  @include lg {
    margin-top: 32px;
  }
}
.top-nav {
  @include lg {
    margin-bottom: 32px;
  }
}

.empty-basket {
  padding: 12px;
  background-color: $lightBgColor;

  &__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;

    @include lg {
      padding-top: calc(23px - 12px);
    }
  }

  &__text {
    padding: 16px 0;
    font-size: 12px;
    line-height: 146%;
  }
}
</style>
