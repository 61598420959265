<template>
  <li class="summary__item">
    <div class="summary__details">
      <p class="summary__product">Dostawa</p>
    </div>
    <div class="summary__value">
      <FormattedPrice :price="price" :currency="currency" />
      <span v-if="isPln(currency)" class="summary__tax">(z VAT)</span>
    </div>
  </li>
</template>

<script>
import FormattedPrice from "../utils/FormattedPrice";

export default {
  name: "SummaryDeliveryItem",
  components: { FormattedPrice },
  props: {
    price: {
      type: Number,
      required: true
    },
    currency: {
      type: String,
      required: false
    }
  },
  methods: {
    isPln(currency) {
      return currency === "PLN";
    }
  }
};
</script>
