<template>
  <div class="buttons">
    <template v-if="!isBasketEmpty">
      <router-link to="/order" tag="button" class="btn btn--primary"
        >Przejdź do kasy</router-link
      >
      <button @click="redirectToBackToShoppingUrl()" class="btn btn--secondary">
        Wróć do zakupów
      </button>
    </template>

    <template v-if="isBasketEmpty">
      <button @click="redirectToBackToShoppingUrl()" class="btn btn--primary">
        Wróć do sklepu
      </button>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Buttons",
  computed: {
    ...mapGetters(["isBasketEmpty", "getBackToShoppingUrl"])
  },
  methods: {
    ...mapActions(["loadBasketData"]),
    async redirectToBackToShoppingUrl() {
      window.location = this.getBackToShoppingUrl;
    }
  }
};
</script>

<style lang="scss" scoped>
.buttons {
  background-color: $lightBgColor;
  padding: 10px;

  @include lg {
    padding: 16px;
  }

  .btn {
    margin-bottom: 8px;
  }
}
</style>
