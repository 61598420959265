<template>
  <div class="summary">
    <p class="summary__title">Do zapłaty</p>
    <ul class="summary__list">
      <SummaryItem v-for="item in getBasketItems" :key="item.id" :item="item" />
      <RabateCodeSummary
        v-if="getRabateCode"
        :rabate-code="getRabateCode"
        :currency="getBasketAmount.currencyIsoCode"
      />
      <SummaryDeliveryItem
        :price="getChosenDeliveryPrice"
        :currency="getChosenDeliveryPriceCurrency"
        v-if="deliveryDisplay && getChosenDeliveryPrice"
      />
    </ul>
    <div class="summary__total">
      <span class="summary__toPay">Do zapłaty:</span>
      <FormattedPrice
        :price="getBasketAmount.gross"
        :currency="getBasketAmount.currencyIsoCode"
      />
      <span v-if="isPln(getBasketAmount.currencyIsoCode)" class="summary__tax"
        >(z VAT)</span
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SummaryItem from "./SummaryItem";
import RabateCodeSummary from "./RabateCodeSummary";
import SummaryDeliveryItem from "./SummaryDeliveryItem";
import FormattedPrice from "../utils/FormattedPrice";

export default {
  name: "Summary",
  props: {
    deliveryDisplay: {
      type: Boolean,
      default: false
    }
  },
  components: {
    FormattedPrice,
    SummaryItem,
    RabateCodeSummary,
    SummaryDeliveryItem
  },
  computed: {
    ...mapGetters([
      "getBasketItems",
      "getBasketAmount",
      "getRabateCode",
      "getChosenDeliveryPrice",
      "getChosenDeliveryPriceCurrency"
    ])
  },
  methods: {
    isPln(currency) {
      return currency === "PLN";
    }
  }
};
</script>

<style lang="scss">
.summary {
  padding: 10px;
  background-color: $lightBgColor;

  @include lg {
    padding: 16px;
  }

  &__title {
    margin-bottom: 18px;
    font-weight: 500;
    @include font-xl;

    @include lg {
      padding-bottom: 16px;
      margin-bottom: 24px;
      border-bottom: 1px solid $hrLineColor;
    }
  }

  &__list {
    margin-bottom: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #a79c9c;

    @include lg {
      margin-bottom: 24px;
    }
  }

  &__total {
    @include font-xl;
    font-weight: 700;
    text-align: right;
  }

  &__toPay {
    margin-right: 6px;
    @include font-sm;
    font-weight: 300;
  }

  &__tax {
    margin-left: 6px;
    @include font-sm;
    font-weight: 300;
  }

  &__item {
    display: flex;
    margin-bottom: 8px;

    @include lg {
      margin-bottom: 16px;
    }
  }

  &__details {
    flex: 1;
  }

  &__product {
    font-weight: 400;
    margin-bottom: 6px;
    @include font-lg;
  }

  &__quantity {
    font-weight: 300;
    @include font-md;
  }

  &__value {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-weight: 500;
    @include font-lg;
  }

  &__tax {
    font-size: 12px;
    line-height: 14px;
    font-weight: 300;

    @include lg {
      font-size: 16px;
      line-height: 19px;
    }
  }
}
</style>
