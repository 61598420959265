export default {
  basketPage: {
    items: [],
    amount: {
      gross: 0
    },
    rabateCodes: [],
    rabateCode: undefined,
    deliveries: [],
    payments: [],
    loggedCustomer: null,
    isAvailable: false
  },
  recommendedForProduct: {},
  sessionId: null,
  cartType: "cart",
  isUserAuthenticated: false,
  isUserNoRegister: false,
  backToShoppingUrl: "",
  customerData: undefined,
  customerHash: undefined,
  orderData: {
    orders: [],
    redirectUrl: null,
    isRedirectRequired: false
  },
  errors: {},
  dataLayer: undefined,
  service: {
    name: "Wiem i Wybieram",
    domain: "wiemiwybieram.pl",
    retUrl: "https://wiemiwybieram.pl",
    accountUrl: "https://konto.wiemiwybieram.pl"
  },
  refererLabel: null,
  authenticationReferer: null,
  isGift: false,
  chosenPaymentCode: null,
  chosenDeliveryId: null,
  deliveryAddressData: null,
  userIpAddress: null,
  thankYouPageTypeKey: {
    default: {
      payment: "default",
      deliveryDescription: "default",
      deliveryOption: "",
      orderStatus: ""
    },
    PP1: {
      payment: "Wybrałeś formę zapłaty przelewem tradycyjnym",
      deliveryDescription:
        "Produkt powinien być u Ciebie za od 2 do 12 dni roboczych.",
      deliveryOption: "Poczta Polska",
      orderStatus: "CASH_TRANSFER"
    },
    KK1: {
      payment: "Wybrałeś formę płatności szybkim przelewem",
      deliveryDescription:
        "Produkt powinien być u Ciebie za od 2 do 12 dni roboczych.",
      deliveryOption: "Poczta Polska",
      orderStatus: "PAYMENT_COMPLETED"
    },
    PP12: {
      payment: "Wybrałeś formę zapłaty przelewem tradycyjnym",
      deliveryDescription:
        "Produkt powinien być u Ciebie za od 2 do 4 dni roboczych.",
      deliveryOption: "Kurier",
      orderStatus: "CASH_TRANSFER"
    },
    KK12: {
      payment: "Wybrałeś formę płatności szybkim przelewem",
      deliveryDescription:
        "Produkt powinien być u Ciebie za od 2 do 4 dni roboczych.",
      deliveryOption: "Kurier",
      orderStatus: "PAYMENT_COMPLETED"
    },
    PP11: {
      payment: "Wybrałeś formę zapłaty przelewem tradycyjnym",
      deliveryDescription:
        "Po opłaceniu zamówienia, o dostępie do produktu na Twoim koncie, poinformujemy w wiadomości email.",
      deliveryOption: "",
      orderStatus: "CASH_TRANSFER"
    },
    KK11: {
      payment: "Wybrałeś formę płatności szybkim przelewem",
      deliveryDescription: "Twoje produkty są dostępne na Twoim koncie",
      deliveryOption: "",
      orderStatus: "PAYMENT_COMPLETED"
    },
    AE11: {
      payment: "Wybrałeś formę płatności Autoodnowienie eCard",
      deliveryDescription: "Twoje produkty są dostępne na Twoim koncie",
      deliveryOption: "",
      orderStatus: "PAYMENT_COMPLETED"
    },
    PO8: {
      payment: "Wybrałeś formę płatności za pobraniem.",
      deliveryDescription:
        "Produkt powinien być u Ciebie za od 2 do 4 dni roboczych.",
      deliveryOption: "Kurier",
      orderStatus: "CASH_ON_DELIVERY"
    }
  },
  debug: null
};
