import VueCookies from "vue-cookies";

export function setCookie(key, value, domain) {
  const cookieTime = 60 * 60 * 24; // 24 godziny
  const secureCookie = !process.env.VUE_APP_DISABLE_SECURE_COOKIE;
  VueCookies.set(
    key,
    value,
    cookieTime,
    "/",
    `.${domain}`,
    secureCookie,
    "None"
  );
}

export function getCookie(key) {
  return VueCookies.get(key);
}
