import getters from "@/store/getters";
import { getCookie, setCookie } from "./cookies";

export class GtmManager {
  stepsMap = {
    Login: { step: 2, option: "Logowanie" },
    Register: { step: 2, option: "Rejestracja" }
  };

  constructor() {}

  _prepareItem(item, singly = false) {
    return {
      name: item.product.name,
      id: item.product.code,
      quantity: singly ? 1 : item.quantity,
      price: item.unitPrice === null ? item.price.gross : item.unitPrice.gross
    };
  }
  _getProduct(context, itemId, singly) {
    const item = getters
      .getBasketItems(context.state)
      .find(i => i.id === itemId);
    return this._prepareItem(item, singly);
  }
  _getProducts(context) {
    const products = getters.getBasketItems(context.state);
    const gtmProducts = [];
    products.forEach(item => {
      gtmProducts.push(this._prepareItem(item));
    });
    return gtmProducts;
  }

  _getDataLayer(context) {
    return getters.getDataLayer(context.state);
  }
  _addCustomerHashToData(context, dataToPush) {
    const hash = getters.getCustomerHash(context.state);
    if (hash) {
      dataToPush["userID"] = hash;
    }
  }
  _addStepToData(context, dataToPush) {
    const actionField = this.stepsMap[dataToPush.virtualpagetitle];
    if (actionField) {
      dataToPush["ecommerce"] = {
        checkout: {
          actionField: actionField,
          products: this._getProducts(context)
        }
      };
    }
  }
  checkout(context) {
    const dataLayer = this._getDataLayer(context);
    dataLayer.push({
      event: "checkout",
      ecommerce: {
        checkout: {
          actionField: { step: 1, option: "" },
          products: this._getProducts(context)
        }
      }
    });
  }
  clear(context) {
    const dataLayer = this._getDataLayer(context);
    dataLayer.push({
      event: "removeFromCart",
      ecommerce: {
        remove: {
          products: this._getProducts(context)
        }
      }
    });
  }
  increaseQuantity(context, itemId) {
    const dataLayer = this._getDataLayer(context);
    dataLayer.push({
      event: "addToCart",
      ecommerce: {
        add: {
          products: [this._getProduct(context, itemId, true)]
        }
      }
    });
  }
  decreaseQuantity(context, itemId) {
    const dataLayer = this._getDataLayer(context);
    dataLayer.push({
      event: "removeFromCart",
      ecommerce: {
        remove: {
          products: [this._getProduct(context, itemId, true)]
        }
      }
    });
  }
  viewChange(context, path, name) {
    const dataLayer = this._getDataLayer(context);
    const dataToPush = {
      event: "virtualpageview",
      virtualpageurl: path,
      virtualpagetitle: name
    };
    this._addCustomerHashToData(context, dataToPush);
    this._addStepToData(context, dataToPush);
    dataLayer.push(dataToPush);
  }
  chosenDeliveryEvent(context, name) {
    const dataLayer = this._getDataLayer(context);
    dataLayer.push({
      event: "checkout",
      ecommerce: {
        checkout: {
          actionField: { step: 3, option: name },
          products: this._getProducts(context)
        }
      }
    });
  }
  chosenPaymentEvent(context, name) {
    const dataLayer = this._getDataLayer(context);
    dataLayer.push({
      event: "checkout",
      ecommerce: {
        checkout: {
          actionField: { step: 4, option: name },
          products: this._getProducts(context)
        }
      }
    });
  }
  ordersConfirm(context, orders, transactionId) {
    const cookieKey = "gtm_transaction_" + transactionId + "_sent";
    if (getCookie(cookieKey) === null) {
      const dataLayer = this._getDataLayer(context);
      let revenue = 0;
      let tax = 0;
      let shipping = 0;
      const products = [];
      orders.forEach(elem => {
        revenue += elem.amount.gross;
        tax += elem.amount.vat;
        shipping += elem.delivery.price != null ? elem.delivery.price.gross : 0;
        elem.items.forEach(item => {
          products.push(this._prepareItem(item));
        });
      });

      let dataToPush = {
        event: "virtualpageview",
        virtualpageurl: "/order-confirm",
        virtualpagetitle: "OrderConfirm",
        ecommerce: {
          purchase: {
            actionField: {
              id: transactionId,
              revenue: revenue,
              tax: tax,
              shipping: shipping
            },
            products: products
          }
        }
      };

      const rabateCode = getters.getRabateCode(context.state);
      if (rabateCode) {
        dataToPush.ecommerce.purchase.actionField.coupon = rabateCode.code;
      }

      const refererLabel = getters.getRefererLabel(context.state);
      if (refererLabel != null) {
        dataToPush.refererLabel = refererLabel;
      }

      dataLayer.push({ ecommerce: null });
      dataLayer.push(dataToPush);

      const service = getters.getService(context.state).domain;
      setCookie(cookieKey, true, service);
    }
  }
  formSubmit(context, formName) {
    const dataLayer = this._getDataLayer(context);
    const dataToPush = {
      event: "formSubmit1",
      formID: formName
    };
    this._addCustomerHashToData(context, dataToPush);
    dataLayer.push(dataToPush);
  }

  purchaseEvent(context, transactionId) {
    const dataLayer = this._getDataLayer(context);
    const purchaseData = getters.getOrderPurchaseData(context.state);
    const products = getters.getBasketItems(context.state);
    let dataToPush = {
      event: "purchase",
      ecommerce: {
        transaction_id: transactionId,
        value: purchaseData.amount,
        tax: purchaseData.vat,
        shipping: getters.getChosenDeliveryPrice(context.state),
        currency:
          purchaseData.currency !== null ? purchaseData.currency : "PLN",
        coupon:
          purchaseData.rabateCode !== undefined
            ? purchaseData.rabateCode.code
            : "",
        items: products.map(item => ({
          item_id: item.product.code,
          item_name: item.product.name,
          discount:
            item.price.rabateGross !== null ? item.price.rabateGross : 0,
          price:
            item.unitPrice === null ? item.price.gross : item.unitPrice.gross,
          quantity: item.quantity
        }))
      }
    };
    dataLayer.push({ ecommerce: null });
    dataLayer.push(dataToPush);
  }
}

export const gtmManager = new GtmManager();
