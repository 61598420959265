<template>
  <div>
    <nav class="top-nav">
      <div class="top-nav__container">
        <a :href="'https://' + this.getService.domain">
          <div class="top-nav__logo"></div>
        </a>
        <div v-if="displayContact" class="top-nav__contact">
          <div class="top-nav__tel">22&nbsp;59&nbsp;05&nbsp;555</div>
          <div class="top-nav__hours">
            <span>pn.-pt.&nbsp;8-21,&nbsp;sb.-nd.&nbsp;9-17</span>
          </div>
        </div>
      </div>
    </nav>
    <Bulletin />
  </div>
</template>

<script>
import Bulletin from "../../components/utils/Bulletin";
import { mapGetters } from "vuex";
export default {
  name: "Header",
  components: { Bulletin },
  computed: {
    ...mapGetters(["getService"]),
    displayContact: function() {
      return location.hostname.includes("koszyk.muratordom.pl");
    }
  }
};
</script>

<style lang="scss">
.top-nav {
  background-color: $lightBgColor;

  @include lg {
    padding: 24px 8px;
  }

  &__container {
    padding: 16px 8px;
    margin: 0 auto;
    width: 1000px;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__tel {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    @include lg {
      font-size: 18px;
      line-height: 32px;
      font-weight: normal;
    }
  }
  &__hours {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 14x;
    color: #4f4f4f;
    @include lg {
      font-display: 12px;
      line-height: 20px;
    }
  }
  &__logo {
    width: 213px;
    height: 15px;
    background-image: var(--logotype);
    background-size: contain;
    background-repeat: no-repeat;

    @include lg {
      width: 220px;
      margin-top: 0;
    }
  }
}
</style>
