import axios from "axios";
import https from "https";
import router from "@/router/index";
import { sha256 } from "js-sha256";
import { getOrderDataSourceId } from "./utils";

export const unauthorizedAgent = new https.Agent({ rejectUnauthorized: false });

function priceToHash(price) {
  const priceComponents = price
    .toString()
    .split(".")
    .map(p => parseInt(p));
  if (priceComponents.length > 1)
    if (priceComponents[1] < 10)
      return priceComponents[0] * 100 + priceComponents[1] * 10;
    else return priceComponents[0] * 100 + priceComponents[1];
  else return priceComponents[0] * 100;
}

export class WeaverAdapter {
  constructor() {
    this.endpoint = process.env.VUE_APP_BASE_ENDPOINT;
  }

  _addSecretToHeaders(headers, sessionId, endpointCommand) {
    const endpointName = endpointCommand.split("/")[1];
    let privateKey = process.env.VUE_APP_WEAVE_PRIVATE_KEY;
    let publicKey = process.env.VUE_APP_WEAVE_PUBLIC_KEY;
    if (
      (window.location &&
        window.location.href &&
        window.location.href.includes("murator")) ||
      window.location.host.includes("murator")
    ) {
      privateKey = process.env.VUE_APP_MURATOR_WEAVE_PRIVATE_KEY;
      publicKey = process.env.VUE_APP_MURATOR_WEAVE_PUBLIC_KEY;
    }

    const toHash = privateKey + publicKey + sessionId + endpointName;
    headers["Authorization-Secret"] = sha256(toHash.toLowerCase());
  }

  _prepareHeaders(sessionId, endpointCommand, withSecret) {
    function notBlockedSecret() {
      return !(parseInt(process.env.VUE_APP_BLOCK_SECRET) !== 0);
    }
    let publicKey = process.env.VUE_APP_WEAVE_PUBLIC_KEY;
    if (
      (window.location &&
        window.location.href &&
        window.location.href.includes("murator")) ||
      window.location.host.includes("murator")
    ) {
      publicKey = process.env.VUE_APP_MURATOR_WEAVE_PUBLIC_KEY;
    }
    const headers = {
      "Authorization-Token": sessionId,
      "Authorization-PublicKey": publicKey
    };
    if (withSecret && notBlockedSecret()) {
      this._addSecretToHeaders(headers, sessionId, endpointCommand);
    }
    return headers;
  }
  _doAxios(
    context,
    method,
    endpointCommand,
    sessionId,
    commands,
    data = undefined,
    withSecret = true
  ) {
    return axios({
      method: method,
      url: this.endpoint + endpointCommand,
      data: data,
      headers: this._prepareHeaders(sessionId, endpointCommand, withSecret),
      httpsAgent: unauthorizedAgent,
      timeout: 2000
    })
      .then(response => {
        if (response.status === 200) {
          commands.success(response.data);
        } else if (response.status === 204) {
          commands.success(response.data);
        } else {
          commands.failure(response.data);
        }
      })
      .catch(async error => {
        if (error.response) {
          if (error.response.status === 401) {
            await context.dispatch("clearUserData");
            router.push({ name: "Login" });
          }
          commands.failure({
            ...error.response.data,
            status: error.response.status
          });
        }
      });
  }

  /* Basket */
  insertToBasket(context, data, commands) {
    const productData = data.productData;
    const gross = productData["gross"] ? productData["gross"] : 0;
    const grossToHash = priceToHash(gross);
    let toHash =
      "1" + grossToHash + "PLN" + process.env.VUE_APP_WEAVE_PRIVATE_KEY;
    if (
      (window.location &&
        window.location.href &&
        window.location.href.includes("murator")) ||
      window.location.host.includes("murator")
    ) {
      toHash =
        "1" +
        grossToHash +
        "PLN" +
        process.env.VUE_APP_MURATOR_WEAVE_PRIVATE_KEY;
    }
    const hash = sha256(toHash.toLowerCase());
    const dataToSend = {
      items: [
        {
          offerCode: productData["offerCode"],
          quantity: 1,
          startingDate: productData["startingDate"],
          startingEmissionId: null,
          baseGross: 0,
          gross: gross,
          currencyIsoCode: "PLN",
          hash: hash
        }
      ],
      cartType: "Standard"
    };

    return this._doAxios(
      context,
      "put",
      "basket/InsertToBasket/",
      data.sessionId,
      commands,
      dataToSend
    );
  }
  getPage(context, data, commands) {
    const dataToSend = {
      cartType: "Standard"
    };
    return this._doAxios(
      context,
      "post",
      "basket/GetPage/",
      data.sessionId,
      commands,
      dataToSend
    );
  }
  changeQuantity(context, data, commands) {
    const dataToSend = {
      cartType: "Standard",
      cartOfferId: data.basketItemId,
      newQuantity: data.newQuantity
    };
    return this._doAxios(
      context,
      "post",
      "basket/ChangeBasketItemQuantity/",
      data.sessionId,
      commands,
      dataToSend
    );
  }
  deleteItem(context, data, commands) {
    const dataToSend = {
      cartType: "Standard",
      cartOfferId: data.basketItemId
    };
    return this._doAxios(
      context,
      "post",
      "basket/DeleteItem/",
      data.sessionId,
      commands,
      dataToSend
    );
  }
  clearBasket(context, data, commands) {
    const dataToSend = {
      cartType: "Standard"
    };
    return this._doAxios(
      context,
      "post",
      "basket/ClearBasket/",
      data.sessionId,
      commands,
      dataToSend
    );
  }
  selectPayment(context, data, commands) {
    const dataToSend = {
      paymentFormId: data.paymentCode,
      cartType: "Standard"
    };
    return this._doAxios(
      context,
      "post",
      "basket/SelectPayment/",
      data.sessionId,
      commands,
      dataToSend
    );
  }
  selectDelivery(context, data, commands) {
    const dataToSend = {
      formOfDistributionId: data.deliveryId,
      cartType: "Standard"
    };
    return this._doAxios(
      context,
      "post",
      "basket/SelectDelivery/",
      data.sessionId,
      commands,
      dataToSend
    );
  }
  useRabateCode(context, data, commands) {
    const dataToSend = {
      code: data.rabateCode,
      cartType: data.cartType
    };
    return this._doAxios(
      context,
      "post",
      "basket/UseRabateCode/",
      data.sessionId,
      commands,
      dataToSend
    );
  }
  editCustomer(context, data, commands) {
    data.payerAddress = this.convertAddress(data.payerAddress);

    if (data.reciverAddress) {
      data.reciverAddress = this.convertAddress(data.reciverAddress);
    }

    const dataToSend = {
      payerAddressInputModel: data.payerAddress,
      reciverAddressInputModel: data.reciverAddress
    };
    return this._doAxios(
      context,
      "post",
      "customer/EditCustomer/",
      data.sessionId,
      commands,
      dataToSend
    );
  }

  convertAddress(address) {
    address.countryIso2Code = "PL";
    address.dataSourceId = "98cc31ae-6b51-4455-8e8a-20d4f3fcc4b8";
    delete address.postCode;
    delete address.countries;
    delete address.country;

    if (address.street !== undefined) {
      const buildingAndFlatNumber = address.street.split(" ").pop();
      const hasBuildingNumber =
        parseInt(buildingAndFlatNumber.split("/")[0]) > 0;
      if (hasBuildingNumber) {
        address.buildingNumber = buildingAndFlatNumber.split("/")[0];
        address.flatNumber = buildingAndFlatNumber.split("/")[1];
        address.street = address.street
          .replace(buildingAndFlatNumber, "")
          .trim();
      }
    }

    return address;
  }

  removeRabateCode(context, data, commands) {
    const dataToSend = {
      cartType: data.cartType
    };
    return this._doAxios(
      context,
      "post",
      "basket/RemoveRabateCode/",
      data.sessionId,
      commands,
      dataToSend
    );
  }

  /* Customer */
  deficientRegister(context, data, commands) {
    const dataToSend = {
      login: data.login,
      password: data.password,
      regulationConsent: data.regulationConsent,
      phoneMarketingConsent: false,
      emailMarketingConsent: data.emailMarketingConsent,
      customerDataSourceId: "98CC31AE-6B51-4455-8E8A-20D4F3FCC4B8",
      withoutOptIn: true
    };
    return this._doAxios(
      context,
      "put",
      "customer/DeficientRegister/",
      data.sessionId,
      commands,
      dataToSend
    );
  }
  login(context, data, commands) {
    const dataToSend = {
      login: data.login,
      password: data.password,
      sessionDuration: data.sessionDuration,
      subjectId: "256FE55B-E6BB-447F-B615-4D65A45BCAF5"
    };

    return this._doAxios(
      context,
      "post",
      "customer/Login/",
      data.sessionId,
      commands,
      dataToSend
    );
  }
  logOut(context, data, commands) {
    return this._doAxios(
      context,
      "post",
      "customer/LogOut/",
      data.sessionId,
      commands
    );
  }
  getCustomer(context, data, commands) {
    return this._doAxios(
      context,
      "get",
      "customer/Get/",
      data.sessionId,
      commands
    );
  }
  checkAccount(context, data, commands) {
    const path = `customer/CheckAccount/${data.login}/`;
    return this._doAxios(context, "get", path, data.sessionId, commands);
  }

  /* Order */
  realizeForExistingCustomer(context, data, commands) {
    const dataToSend = {
      cartType: "Standard",
      ip: data.ip,
      redirectUrl: data.redirectUrl,
      orderDataSourceId: getOrderDataSourceId(data.redirectUrl)
    };
    return this._doAxios(
      context,
      "post",
      "order/RealizeForExistingCustomer/",
      data.sessionId,
      commands,
      dataToSend
    );
  }
  realizeForNewCustomer(context, data, commands) {
    data.invoiceAddress.countryIso2Code = "PL";
    data.invoiceAddress.dataSourceId = "98cc31ae-6b51-4455-8e8a-20d4f3fcc4b8";
    const dataToSend = {
      newCustomer: {
        dataSourceId: "98cc31ae-6b51-4455-8e8a-20d4f3fcc4b8",
        createAccount: true,
        invoiceAddress: data.invoiceAddress,
        deliveryAddress: data.deliveryAddress,
        regulationConsent: data.regulationConsent,
        phoneMarketingConsent:
          data.phoneMarketingConsent === undefined
            ? false
            : data.phoneMarketingConsent,
        emailMarketingConsent: data.emailMarketingConsent
      },
      cartType: "Standard",
      ip: data.ip,
      redirectUrl: data.redirectUrl,
      orderDataSourceId: getOrderDataSourceId(data.redirectUrl)
    };
    return this._doAxios(
      context,
      "post",
      "order/RealizeForNewCustomer/",
      data.sessionId,
      commands,
      dataToSend
    );
  }
  startSubscriptionFromOwned(context, data, commands) {
    const dataToSend = {
      cartType: data.cartType,
      basketItemId: data.basketItemId
    };
    return this._doAxios(
      context,
      "post",
      "basket/StartSubscriptionFromOwned/",
      data.sessionId,
      commands,
      dataToSend
    );
  }

  /* History */
  getOrdersByTransaction(context, data, commands) {
    return this._doAxios(
      context,
      "get",
      "history/GetOrdersByTransaction/?masterOrderId=" + data.transactionId,
      data.sessionId,
      commands
    );
  }
}

export class EloquaAdapter {
  constructor() {}

  registerCustomer(customerData) {
    const data = new FormData();
    data.append("elqFormName", "Form_e-sklep2-ZPR_Koszyk");
    data.append("elqSiteID", process.env.VUE_APP_ELOQUA_SITE_ID);
    data.append("elqCookieWrite", "0");
    data.append("elqCustomerGUID", customerData.elqCustomerGUID);
    data.append("Email_Address", customerData.email);
    data.append("zgoda_Time", customerData.emailMarketingConsent ? "on" : "");
    data.append(
      "zgoda_Time_mail",
      customerData.emailMarketingConsent ? "on" : ""
    );
    data.append("f_zrodlo_data", this._today());
    data.append("f_serwis", window.location.host);
    customerData.items.forEach((elem, index) => {
      const key = "produkt" + (index + 1);
      const value = elem.product.name;
      data.append(key, value);
    });
    axios({
      method: "post",
      url: process.env.VUE_APP_ELOQUA_URL,
      data: data
    });
  }
  _today() {
    const date = new Date();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return month + "/" + day + "/" + year;
  }
}

export class IpAddressAdapter {
  constructor() {
    this.endpoint = "https://api.ipify.org/";
  }
  getIpAddress() {
    return this._doAxios();
  }

  _doAxios(method = "get", timeout = 2000) {
    return axios({
      method: method,
      url: this.endpoint,
      timeout: timeout
    })
      .then(response => {
        if (response.status === 200) {
          return response.data;
        } else {
          return null;
        }
      })
      .catch(async () => {
        return null;
      });
  }
}

export const adapter = new WeaverAdapter();
export const elqAdapter = new EloquaAdapter();
export const ipAddressAdapter = new IpAddressAdapter();
