import Vue from "vue";
import VueRouter from "vue-router";
import Basket from "../views/Basket.vue";
import AddProduct from "../views/AddProduct.vue";
import Login from "../views/Login";
import Register from "../views/Register";
import Order from "../views/Order";
import OrderConfirm from "../views/OrderConfirm";
import guards from "./guards";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Basket",
    component: Basket
  },
  {
    path: "/add",
    name: "AddProduct",
    component: AddProduct
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    beforeEnter: guards.checkUserIsAuthenticatedAndRedirectToOrder
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    beforeEnter: guards.checkUserIsAuthenticatedAndRedirectToOrder
  },
  {
    path: "/order",
    name: "Order",
    component: Order,
    beforeEnter: guards.checkUserIsAuthenticatedAndRedirectToLogin
  },
  {
    path: "/order-confirm",
    name: "OrderConfirm",
    component: OrderConfirm
  }
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
});
router.beforeEach(guards.checkUserIsAuthenticated);
router.beforeResolve(guards.gtmChangePage);

export default router;
