var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"login-page"}},[_c('Header'),_c('Steps'),_c('ErrorBox',{ref:"errorBox",attrs:{"errors":_vm.loginErrors}}),_c('div',{staticClass:"loginBox"},[_c('div',{staticClass:"container col1-2"},[_c('div',{staticClass:"login"},[_c('p',{staticClass:"login__title"},[_vm._v("Mam konto do zakupów")]),_c('div',{class:(_vm.$v.email.$dirty && _vm.$v.email.$invalid) || _vm.$v.errors.$invalid
              ? 'form__box form__box--error'
              : 'form__box'},[_c('p',{staticClass:"input__title"},[_vm._v("Adres e-mail")]),_c('div',{class:_vm.$v.email.$invalid || _vm.$v.errors.$invalid
                ? 'form__inputBox'
                : 'form__inputBox form__inputBox--valid'},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form__input",attrs:{"type":"email","placeholder":"podaj adres e-mail"},domProps:{"value":(_vm.email)},on:{"blur":_vm.$v.email.$touch,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.logUserIn()},"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}})]),(!_vm.$v.email.required)?_c('p',{staticClass:"input__error"},[_vm._v(" Podaj adres email ")]):_vm._e(),(!_vm.$v.email.email)?_c('p',{staticClass:"input__error"},[_vm._v(" Podaj poprawny adres email ")]):_vm._e()]),_c('div',{class:(_vm.$v.password.$dirty && _vm.$v.password.$invalid) || _vm.$v.errors.$invalid
              ? 'form__box form__box--error'
              : 'form__box'},[_c('p',{staticClass:"input__title"},[_vm._v("Hasło")]),_c('div',{class:_vm.$v.password.$invalid || _vm.$v.errors.$invalid
                ? 'form__inputBox'
                : 'form__inputBox form__inputBox--valid'},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form__input",attrs:{"type":"password","placeholder":"hasło powinno zawierać 6 znaków"},domProps:{"value":(_vm.password)},on:{"blur":_vm.$v.password.$touch,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.logUserIn()},"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}})]),(!_vm.$v.password.required)?_c('p',{staticClass:"input__error"},[_vm._v("Podaj hasło")]):_vm._e()]),_c('div',{staticClass:"pswdReset"},[_c('p',{staticClass:"pswdReset__title",attrs:{"id":"reset-pass-btn"},on:{"click":function($event){return _vm.resetPassword()}}},[_vm._v(" Zapomniałem hasła ")])]),_c('button',{class:_vm.$v.$invalid ? 'btn btn--inactive' : 'btn btn--primary',attrs:{"id":"login-btn"},on:{"click":function($event){return _vm.logUserIn()}}},[_vm._v(" Zaloguj się ")]),_c('router-link',{staticClass:"btn btn--secondaryLight",attrs:{"to":"/","tag":"button"}},[_vm._v(" Wróć do zakupów ")])],1)]),_c('div',{staticClass:"container container--register col1-2",attrs:{"id":"toggle-login-register"}},[_c('div',{staticClass:"register-option"},[_c('div',{staticClass:"register-option__btnBox"},[_c('ToggleLoginRegister')],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }