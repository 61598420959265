var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"register-form"}},[_c('div',{staticClass:"register"},[_c('div',{class:_vm.$v.email.$dirty && _vm.$v.email.$invalid
          ? 'form__box form__box--error'
          : 'form__box'},[_c('p',{staticClass:"input__title"},[_vm._v("Adres e-mail")]),_c('div',{class:_vm.$v.email.$invalid
            ? 'form__inputBox'
            : 'form__inputBox form__inputBox--valid'},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form__input",attrs:{"type":"email","placeholder":"podaj adres e-mail"},domProps:{"value":(_vm.email)},on:{"blur":_vm.$v.email.$touch,"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}})]),(!_vm.$v.email.required)?_c('p',{staticClass:"input__error"},[_vm._v(" Podaj adres e-mail ")]):_vm._e(),(!_vm.$v.email.email)?_c('p',{staticClass:"input__error"},[_vm._v(" Podaj poprawny adres e-mail ")]):_vm._e(),(_vm.accountExist)?_c('p',{staticClass:"input__warning"},[_vm._v(" Na ten adres e-mail jest założone konto do zakupów w grupie ZPR Media. Zaloguj się do konta lub użyj innego adresu e-mail. ")]):_vm._e()]),_c('div',{class:_vm.$v.password.$dirty && _vm.$v.password.$invalid
          ? 'form__box form__box--error'
          : 'form__box'},[_c('p',{staticClass:"input__title"},[_vm._v("Hasło")]),_c('div',{class:_vm.$v.password.$invalid
            ? 'form__inputBox'
            : 'form__inputBox form__inputBox--valid'},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form__input",attrs:{"type":"password","placeholder":"hasło powinno zawierać 6 znaków"},domProps:{"value":(_vm.password)},on:{"blur":_vm.$v.password.$touch,"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}})]),(!_vm.$v.password.required)?_c('p',{staticClass:"input__error"},[_vm._v("Podaj hasło")]):_vm._e(),(!_vm.$v.password.minLength)?_c('p',{staticClass:"input__error"},[_vm._v(" Hasło powinno zawierać min. 6 znaków ")]):_vm._e()]),_c('div',{class:_vm.$v.passwordRepeat.$dirty && _vm.$v.passwordRepeat.$invalid
          ? 'form__box form__box--error'
          : 'form__box'},[_c('p',{staticClass:"input__title"},[_vm._v("Powtórz hasło")]),_c('div',{class:_vm.$v.passwordRepeat.$invalid
            ? 'form__inputBox'
            : 'form__inputBox form__inputBox--valid'},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.passwordRepeat),expression:"passwordRepeat"}],staticClass:"form__input",attrs:{"type":"password","placeholder":"powtórz hasło"},domProps:{"value":(_vm.passwordRepeat)},on:{"blur":_vm.$v.passwordRepeat.$touch,"input":function($event){if($event.target.composing){ return; }_vm.passwordRepeat=$event.target.value}}})]),(!_vm.$v.passwordRepeat.required)?_c('p',{staticClass:"input__error"},[_vm._v(" Powtórz hasło ")]):_vm._e(),(!_vm.$v.passwordRepeat.sameAsPassword)?_c('p',{staticClass:"input__error"},[_vm._v(" Hasła powinny być takie same ")]):_vm._e()])]),_vm._t("default"),_c('RegulationsTerms',{ref:"regulationsTerms",attrs:{"displayUsageRule":false}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }