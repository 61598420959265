<template>
  <li class="summary__item">
    <div class="summary__details">
      <p class="summary__product">{{ item.product.name }}</p>
      <div class="summary__quantity">
        ilość: <Quantity :number="item.quantity" />
      </div>
    </div>
    <div class="summary__value">
      <FormattedPrice
        :price="
          item.price.strikedGross ? item.price.strikedGross : item.price.gross
        "
        :currency="item.price.currencyIsoCode"
      />
      <span v-if="isPln(item.price.currencyIsoCode)" class="summary__tax"
        >(z VAT)</span
      >
    </div>
  </li>
</template>

<script>
import Quantity from "../utils/Quantity";
import FormattedPrice from "../utils/FormattedPrice";

export default {
  name: "SummaryItem",
  components: { FormattedPrice, Quantity },
  props: {
    item: Object
  },
  methods: {
    isPln(currency) {
      return currency === "PLN";
    }
  }
};
</script>
