<template>
  <div class="items">
    <div class="items__topbar">
      <p class="items__title">Zawartość koszyka</p>
      <button
        class="items__clear"
        v-if="!isBasketEmpty"
        @click="clearBasketModal = true"
      >
        Wyczyść koszyk
      </button>
    </div>
    <ul class="items__list">
      <Item v-for="item in getBasketItems" :key="item.id" :item="item" />
    </ul>
    <Modal
      v-if="clearBasketModal"
      modalTitle="Wyczyść koszyk"
      modalSubtitle="Czy usunąć całą zawartość koszyka?"
      modalOptionMain="Tak, wyczyść koszyk"
      modalOptionSecond="Nie, zostaw produkty w koszyku"
      v-on:selectModalOption="resolveClearBasketModal"
    />
  </div>
</template>

<script>
import Item from "./Item";
import Modal from "./Modal";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ProductList",
  components: { Item, Modal },
  data() {
    return {
      clearBasketModal: false
    };
  },
  computed: {
    ...mapGetters(["getBasketItems", "isBasketEmpty"])
  },
  methods: {
    ...mapActions(["clearBasketItems"]),
    resolveClearBasketModal(value) {
      if (value === 1) {
        this.clearBasketItems();
        this.clearBasketModal = false;
      } else {
        this.clearBasketModal = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.items {
  @include lg {
    padding: 0 12px;
    background-color: $lightBgColor;
  }

  &__topbar {
    padding: 12px;
    display: flex;
    align-items: center;

    @include lg {
      padding: 16px 0;
      background-color: $lightBgColor;
      border-bottom: 1px solid #c4c4c4;
    }
  }

  &__title {
    flex: 1;
    font-weight: 500;
    @include font-xl;
  }

  &__clear {
    @include font-md;
    color: var(--mainColor);
    background: none;
    outline: none;
    border: none;
    padding: 0;
    font-family: $fontPrimary;
    cursor: pointer;
  }
}
</style>
