<template>
  <div id="toggle-login-register" class="registerBtn">
    <router-link
      id="toggle-login-register-btn-second"
      class="btn btn--secondaryLight"
      :to="route[toRouteSecondary].to"
      tag="button"
    >
      {{ route[toRouteSecondary].name }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: "ToggleLoginRegister",
  data() {
    return {
      route: {
        1: {
          name: "Zarejestruj się",
          to: "/register"
        },
        2: {
          name: "Zaloguj się",
          to: "/login"
        }
      }
    };
  },
  computed: {
    toRouteSecondary() {
      return this.$route.name === "Register" ? 2 : 1;
    }
  }
};
</script>

<style lang="scss" scoped>
.registerBtn {
  width: 100%;
  display: flex;

  @include lg {
    margin-bottom: 8px;
  }
}
</style>
