export default {
  mounted() {
    let timerId = null;
    let timeout = 5;
    function waitUntilElq() {
      if (timerId) {
        if (timeout === 0) {
          return;
        }
        if (window._elqQ !== undefined) {
          window._elqQ.push(["elqGetCustomerGUID"]);
          return;
        }
        timeout -= 1;
      }
      timerId = setTimeout("waitUntilElq()", 500);
    }
    timerId = setTimeout(waitUntilElq, 500);
  },
  methods: {
    getElqCustomerGUID() {
      if (
        window.GetElqCustomerGUID &&
        typeof window.GetElqCustomerGUID === "function"
      ) {
        return window.GetElqCustomerGUID();
      } else {
        return undefined;
      }
    }
  }
};
