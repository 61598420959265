<template>
  <div id="register-form">
    <div class="register">
      <div
        :class="
          $v.email.$dirty && $v.email.$invalid
            ? 'form__box form__box--error'
            : 'form__box'
        "
      >
        <p class="input__title">Adres e-mail</p>
        <div
          :class="
            $v.email.$invalid
              ? 'form__inputBox'
              : 'form__inputBox form__inputBox--valid'
          "
        >
          <input
            v-model="email"
            @blur="$v.email.$touch"
            type="email"
            class="form__input"
            placeholder="podaj adres e-mail"
          />
        </div>
        <p v-if="!$v.email.required" class="input__error">
          Podaj adres e-mail
        </p>
        <p v-if="!$v.email.email" class="input__error">
          Podaj poprawny adres e-mail
        </p>
        <p v-if="accountExist" class="input__warning">
          Na ten adres e-mail jest założone konto do zakupów w grupie ZPR Media.
          Zaloguj się do konta lub użyj innego adresu e-mail.
        </p>
      </div>

      <div
        :class="
          $v.password.$dirty && $v.password.$invalid
            ? 'form__box form__box--error'
            : 'form__box'
        "
      >
        <p class="input__title">Hasło</p>
        <div
          :class="
            $v.password.$invalid
              ? 'form__inputBox'
              : 'form__inputBox form__inputBox--valid'
          "
        >
          <input
            v-model="password"
            @blur="$v.password.$touch"
            type="password"
            class="form__input"
            placeholder="hasło powinno zawierać 6 znaków"
          />
        </div>
        <p v-if="!$v.password.required" class="input__error">Podaj hasło</p>
        <p v-if="!$v.password.minLength" class="input__error">
          Hasło powinno zawierać min. 6 znaków
        </p>
      </div>

      <div
        :class="
          $v.passwordRepeat.$dirty && $v.passwordRepeat.$invalid
            ? 'form__box form__box--error'
            : 'form__box'
        "
      >
        <p class="input__title">Powtórz hasło</p>
        <div
          :class="
            $v.passwordRepeat.$invalid
              ? 'form__inputBox'
              : 'form__inputBox form__inputBox--valid'
          "
        >
          <input
            v-model="passwordRepeat"
            @blur="$v.passwordRepeat.$touch"
            type="password"
            class="form__input"
            placeholder="powtórz hasło"
          />
        </div>
        <p v-if="!$v.passwordRepeat.required" class="input__error">
          Powtórz hasło
        </p>
        <p v-if="!$v.passwordRepeat.sameAsPassword" class="input__error">
          Hasła powinny być takie same
        </p>
      </div>
    </div>

    <slot></slot>

    <RegulationsTerms :displayUsageRule="false" ref="regulationsTerms" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";
import eloquaMixin from "../../mixins/eloquaMixin";
import RegulationsTerms from "./RegulationsTerms";

export default {
  name: "RegisterForm",
  components: {
    RegulationsTerms
  },
  mixins: [validationMixin, eloquaMixin],
  props: {
    displayUsageRule: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      email: "",
      password: "",
      passwordRepeat: "",
      accountExist: false,
      registerErrors: []
    };
  },
  validations: {
    email: { required, email },
    password: { required, minLength: minLength(6) },
    passwordRepeat: {
      required,
      sameAsPassword: sameAs("password")
    }
  },
  computed: {
    isFormValid: function() {
      return !this.$v.$invalid && !this.$refs.regulationsTerms.$v.$invalid;
    }
  },
  methods: {
    ...mapActions(["registerCustomer"]),
    checkFormAndTryRegisterCustomer() {
      this.touchForms();
      if (this.isFormValid) {
        this.registerUser();
      }
    },
    touchForms() {
      this.$v.$touch();
      this.$refs.regulationsTerms.$v.$touch();
    },
    async registerUser() {
      this.errors = [];
      this.accountExist = false;

      try {
        await this.registerCustomer({
          login: this.email,
          password: this.password,
          regulationConsent: this.$refs.regulationsTerms.salesReg,
          emailMarketingConsent: this.$refs.regulationsTerms.newsletter,
          elqCustomerGUID: this.getElqCustomerGUID()
        });

        this.$emit("registeredCustomer", true);
      } catch (error) {
        this.$emit("registeredCustomer", false);
        if (error.code === 1) {
          this.accountExist = true;
        } else {
          this.errors.push(error.message);
        }
      }
    }
  }
};
</script>
