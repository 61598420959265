<template>
  <span>{{ formatted_price }} {{ formatted_currency }}</span>
</template>

<script>
export default {
  name: "FormattedPrice",
  props: {
    price: {
      type: Number,
      required: true
    },
    currency: {
      type: String,
      required: false
    }
  },
  computed: {
    formatted_price() {
      return this.price
        .toFixed(2)
        .replace(".", ",")
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
    },
    formatted_currency() {
      if (this.currency === "USD") {
        return "$";
      } else {
        return "zł";
      }
    }
  }
};
</script>
