<template>
  <div class="container">
    <div
      v-if="errors.length > 0"
      class="errors"
      v-bind:class="{ bulletin: isBulletin }"
    >
      <p v-for="(error, index) in errors" :key="index" class="errors__text">
        {{ error }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorBox",
  props: {
    errors: Array,
    isBulletin: Boolean
  }
};
</script>

<style lang="scss" scoped>
.errors {
  width: 100%;
  background-color: $errorColor;
  padding: 14px 8px;
  margin-bottom: 16px;

  &__text {
    @include font-sm;
    color: $lightBgColor;
  }
}

.bulletin {
  background-color: $bulletinColor;
}
</style>
