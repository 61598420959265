import md5 from "js-md5";

export default {
  /* SERVICE */
  getBackToShoppingUrl: state => {
    return state.backToShoppingUrl
      ? state.backToShoppingUrl
      : state.service.retUrl;
  },
  getService: state => {
    return state.service;
  },
  getAccountUrl: state => {
    return state.service.accountUrl;
  },
  getUserIpAddress: state => {
    return state.userIpAddress;
  },

  /* SESSION */
  getSessionId: state => {
    return state.sessionId;
  },
  isUserAuthenticated: state => {
    return state.isUserAuthenticated;
  },
  isUserNoRegister: state => {
    return state.isUserNoRegister;
  },
  getCustomerEmail: state => {
    return state.customerData && state.customerData.email
      ? state.customerData.email
      : "";
  },
  getUserType: state => {
    if (
      state.authenticationReferer != "register" &&
      state.customerData &&
      state.customerData.invoiceAdresses &&
      state.customerData.invoiceAdresses.length > 0
    ) {
      return state.customerData.invoiceAdresses[0].kind == 20
        ? "Company"
        : "Private";
    }
    return null;
  },
  getCustomerHash: state => {
    if (state.customerHash) {
      return state.customerHash;
    } else if (state.customerData) {
      state.customerHash = md5(state.customerData.email);
      return state.customerHash;
    } else {
      return undefined;
    }
  },
  isUserSynchronized: state => {
    if (state.customerData && state.customerData.invoiceAdresses) {
      return !state.customerData.invoiceAdresses.every(function(address) {
        return address.isKindChangeAvailable;
      });
    } else {
      return false;
    }
  },
  getAuthenticationReferer: state => {
    return state.authenticationReferer;
  },
  getCustomerNip: state => {
    if (
      state.customerData &&
      state.customerData.invoiceAdresses &&
      state.customerData.invoiceAdresses.length > 0
    ) {
      return state.customerData.invoiceAdresses[0].nip;
    }
    return "";
  },
  getCustomerName: state => {
    if (
      state.customerData &&
      state.customerData.invoiceAdresses &&
      state.customerData.invoiceAdresses.length > 0
    ) {
      return state.customerData.invoiceAdresses[0].companyName;
    }
    return "";
  },

  /* BASKET */
  getBasketItems: state => {
    return state.basketPage.items;
  },
  isBasketEmpty: state => {
    return state.basketPage.items.length === 0;
  },
  isBasketContainsElectronics: state => {
    const productElectronictsArr = state.basketPage.items.map(
      item => item.product.containsElectronics
    );
    return productElectronictsArr.some(Boolean);
  },
  isBasketContainsPhysicals: state => {
    const productPhysicalsArr = state.basketPage.items.map(
      item => item.product.containsPhysicals
    );
    return productPhysicalsArr.some(Boolean);
  },
  getBasketAmount: state => {
    return state.basketPage.amount;
  },
  getRabateCode: state => {
    return state.basketPage.rabateCode;
  },
  getCartType: state => {
    return state.cartType;
  },
  getLoggedCustomerInvoiceData: state => {
    if (
      !state.basketPage.loggedCustomer ||
      !state.basketPage.loggedCustomer.invoiceAdresses ||
      state.basketPage.loggedCustomer.invoiceAdresses.length === 0
    ) {
      return null;
    } else {
      return state.basketPage.loggedCustomer.invoiceAdresses[0];
    }
  },
  isBasketOverlapsOccured: state => {
    const productOverlapsArr = state.basketPage.items.map(item => {
      return item.overlaps && item.overlaps.occured;
    });
    return productOverlapsArr.some(Boolean);
  },

  /* ORDER */
  getOrdersItems: state => {
    let items = [];
    for (let i = 0; i < state.orderData.orders.length; i++) {
      items = items.concat(state.orderData.orders[i].items);
    }
    return items;
  },
  getOrderPurchaseData: state => {
    const order = state.orderData.orders[0];
    return {
      amount: order.amount.gross,
      vat: order.amount.vat,
      currency: state.basketPage.amount.currencyIsoCode,
      rabateCode: state.basketPage.rabateCode
    };
  },

  /* DELIVERY */
  getDeliveryOptions: state => {
    return state.basketPage.deliveries
      .filter(value => value.isAvailable === true)
      .filter(
        value => value.weaverId != "1c7aba89-3282-486c-aa48-60eebf43ba09"
      );
  },
  getChosenDeliveryPrice: state => {
    const selectedDelivery = state.basketPage.deliveries.find(
      delivery => delivery.isSelected === true
    );
    return selectedDelivery ? selectedDelivery.price.gross : 0;
  },
  getChosenDeliveryPriceCurrency: state => {
    const selectedDelivery = state.basketPage.deliveries.find(
      delivery => delivery.isSelected === true
    );
    const acceptedCurrencies = ["USD", "PLN"];
    return selectedDelivery &&
      acceptedCurrencies.includes(selectedDelivery.price.currencyIsoCode)
      ? selectedDelivery.price.currencyIsoCode
      : "PLN";
  },
  isDeliveryChosen: state => {
    const chosenDelivery = state.basketPage.deliveries.find(
      delivery => delivery.isSelected === true
    );
    return (
      chosenDelivery !== undefined &&
      chosenDelivery.price !== undefined &&
      chosenDelivery.price !== null &&
      chosenDelivery.price.gross !== undefined &&
      chosenDelivery.price.gross > 0
    );
  },

  /* PAYMENT */
  getPaymentOptions: state => {
    return state.basketPage.payments.filter(
      value => value.isAvailable === true
    );
  },
  getChosenPayment: state => {
    return state.basketPage.payments.find(
      payment => payment.isSelected === true
    );
  },
  getRedirectUrlForPayment: state => {
    const data = state.orderData;
    return data.redirectUrl && data.isRedirectRequired
      ? data.redirectUrl
      : null;
  },

  /* TRANSACTION */
  getTransactionId: state => {
    return state.orderData.transactionId;
  },
  getDataForCashTransfer: state => {
    const order = state.orderData.orders[0];
    return {
      amount: order.amount.gross,
      currency: order.amount.currencyIsoCode,
      orderId: order.orderGuid.slice(0, 6)
    };
  },
  getThankYouPageTypeKey: state => {
    const key = state.chosenPaymentCode + state.chosenDeliveryId.toString();
    return (
      state.thankYouPageTypeKey[key] || state.thankYouPageTypeKey["default"]
    );
  },
  isGift: state => {
    return state.isGift;
  },
  isTransactionContainsPhysicals: state => {
    if (state.orderData.orders.length == 0) {
      return false;
    }

    const productPhysicalsArr = state.orderData.orders[0].items.map(
      item => item.product.containsPhysicals
    );
    return productPhysicalsArr.some(Boolean);
  },
  getDeliveryAddress: state => {
    if (
      state.customerData != undefined &&
      state.customerData != null &&
      state.customerData.deliveryAddresses.length > 0
    ) {
      let deliveryAddress = state.customerData.deliveryAddresses[0];
      if (deliveryAddress.postalCode != null) {
        deliveryAddress.postalCode = deliveryAddress.postalCode.replace(
          /(\d{2})(\d{3})/,
          "$1-$2"
        );
      }
      if (deliveryAddress.phone != null) {
        deliveryAddress.phone = deliveryAddress.phone.replace(
          /(\d{3})(\d{3})(\d{3})/,
          "$1 $2 $3"
        );
      }
      return deliveryAddress;
    }

    return null;
  },

  /* GTM */
  getDataLayer: state => {
    return state.dataLayer;
  },
  getRefererLabel: state => {
    return state.refererLabel;
  }
};
